import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import questionsData from "./data/kpi360_questions_multilingual.json";

// 분리된 데이터 파일 가져오기
import sectionInfo from "./data/sectionInfo";
import responseOptions from "./data/responseOptions";
import languages from "./data/languagesList";

export default function PersonalityTestApp() {
  const { t, i18n } = useTranslation();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({ full_name: "", region: "", email: "", nationality: "" });
  const [answers, setAnswers] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [timer, setTimer] = useState(50 * 60);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
  
  // Ref for content scrolling
  const contentRef = useRef(null);

  // 모바일 환경 감지
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  // 언어 변경 함수
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setShowLanguageSelector(false);
  };

  // 타이머 관리
  useEffect(() => {
    if (step === 2 && !hasSubmitted) {
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            alert(t('alerts.timeOut'));
            handleSubmit();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [step, hasSubmitted, t]);

  // 시간 포맷 함수
  const formatTime = (sec) => {
    const m = String(Math.floor(sec / 60)).padStart(2, '0');
    const s = String(sec % 60).padStart(2, '0');
    return `${m}:${s}`;
  };

  // 시작 버튼 함수
  const handleStart = () => setStep(1);

  // 입력 필드 변경 감지 함수
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // 답변 선택 함수
  const handleAnswer = (questionId, value) => {
    setAnswers({ ...answers, [questionId]: value });
  };

  // 폼 제출 함수
  const handleSubmit = async () => {
    if (hasSubmitted) {
      alert(t('alerts.alreadySubmitted'));
      return;
    }
    
    // 답변이 모두 완료되었는지 확인
    const unansweredQuestions = questionsData.filter(q => !answers[q.id]);
    if (unansweredQuestions.length > 0) {
      if (window.confirm(t('alerts.incompleteAnswers', { count: unansweredQuestions.length }))) {
        // 사용자가 확인을 선택하면 제출 진행
      } else {
        // 첫 번째 미응답 질문으로 이동
        const firstUnansweredIndex = questionsData.findIndex(q => !answers[q.id]);
        setCurrentQuestionIndex(firstUnansweredIndex);
        return;
      }
    }
    
    try {
      const response = await fetch("http://localhost:4000/api/kpi360/submit", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ info: formData, answers: answers }),
      });
      if (response.ok) {
        alert(t('alerts.submitSuccess'));
        setHasSubmitted(true);
        setStep(3);
      } else {
        alert(t('alerts.submitFail'));
      }
    } catch (error) {
      alert(t('alerts.connectionError'));
      console.error("Error submitting KPI-360:", error);
    }
  };

  // 시작 화면으로 돌아가기
  const handleBackToIntro = () => {
    if (hasSubmitted) {
      alert(t('alerts.alreadySubmitted'));
      return;
    }
    setStep(0);
  };
  
  // 다음 문항으로 이동
  const handleNextQuestion = () => {
    if (currentQuestionIndex < questionsData.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      // 스크롤 위치 초기화
      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
    }
  };
  
  // 이전 문항으로 이동
  const handlePrevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prevIndex => prevIndex - 1);
      // 스크롤 위치 초기화
      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
    }
  };
  
  // 특정 문항으로 직접 이동
  const jumpToQuestion = (index) => {
    if (index >= 0 && index < questionsData.length) {
      setCurrentQuestionIndex(index);
      // 스크롤 위치 초기화
      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
    }
  };

  // 현재 언어에 맞는 응답 옵션 가져오기
  const getCurrentResponseOptions = () => {
    return responseOptions[i18n.language] || 
           responseOptions['en'] || 
           responseOptions['ko'];
  };

  // 섹션 이름 가져오기
  const getSectionName = (section) => {
    // 다국어 섹션 정보 사용
    if (sectionInfo[section]?.name) {
      if (typeof sectionInfo[section].name === 'object') {
        return sectionInfo[section].name[i18n.language] || 
               sectionInfo[section].name['en'] || 
               sectionInfo[section].name['ko'];
      }
      return sectionInfo[section].name;
    }
    // i18n 번역 사용 (fallback)
    return t(`sections.${section}`);
  };

  // 문항 텍스트 가져오기 (다국어 문항 파일 사용 시)
  const getQuestionText = (question) => {
    // 현재 문항이 단순 문자열이면 그대로 반환
    if (typeof question.text === 'string') {
      return question.text;
    }
    
    // 다국어 문항 지원 (kpi360_questions_multilingual.json 사용 시)
    if (typeof question.text === 'object') {
      return question.text[i18n.language] || 
             question.text['en'] || 
             question.text['ko'] || 
             '문항 텍스트 없음';
    }
    
    return '문항 텍스트 없음';
  };
  
  // 진행 상태 표시
  const renderProgressBar = () => {
    return (
      <div style={{ 
        width: '100%', 
        backgroundColor: '#e5e7eb', 
        height: '8px', 
        borderRadius: '4px',
        marginBottom: '1rem'
      }}>
        <div style={{
          width: `${((currentQuestionIndex + 1) / questionsData.length) * 100}%`,
          backgroundColor: '#4f46e5',
          height: '100%',
          borderRadius: '4px',
          transition: 'width 0.3s ease'
        }} />
      </div>
    );
  };

  // 응답 상태 표시기 (점으로 표시)
  const renderAnswerStatusIndicator = () => {
    const sectionQuestions = questionsData.filter(q => q.section === questionsData[currentQuestionIndex].section);
    const sectionStartIndex = questionsData.findIndex(q => q.section === questionsData[currentQuestionIndex].section);
    
    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: '4px', marginTop: '1rem' }}>
        {sectionQuestions.map((q, idx) => {
          const questionIndex = sectionStartIndex + idx;
          const isAnswered = answers[q.id] !== undefined;
          const isCurrent = questionIndex === currentQuestionIndex;
          
          return (
            <button
              key={q.id}
              onClick={() => jumpToQuestion(questionIndex)}
              style={{
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                backgroundColor: isAnswered ? '#10b981' : '#e5e7eb',
                border: isCurrent ? '2px solid #4f46e5' : 'none',
                padding: 0,
                cursor: 'pointer'
              }}
              aria-label={`Question ${questionIndex + 1}`}
            />
          );
        })}
      </div>
    );
  };

  // 언어 선택기 렌더링
  const renderLanguageSelector = () => {
    if (!showLanguageSelector) return null;
    
    return (
      <div style={{
        position: 'absolute',
        top: '100%',
        right: '0',
        backgroundColor: 'white',
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        borderRadius: '0.5rem',
        padding: '1rem',
        maxHeight: '60vh',
        overflow: 'auto',
        zIndex: 10,
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '0.5rem'
      }}>
        {Object.entries(languages).map(([code, name]) => (
          <button
            key={code}
            onClick={() => changeLanguage(code)}
            style={{
              padding: '0.5rem',
              backgroundColor: i18n.language === code ? '#4f46e5' : '#e5e7eb',
              color: i18n.language === code ? 'white' : 'black',
              border: 'none',
              borderRadius: '0.5rem',
              fontSize: '0.9rem',
            }}
          >
            {name}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div style={{ 
      minHeight: "100vh", 
      backgroundColor: "#f9fafb", 
      display: "flex", 
      flexDirection: "column", 
      alignItems: "center", 
      padding: isMobile ? "1rem" : "1.5rem",
      position: "relative" 
    }}>
      <img 
        src="/logo/KPI-360_logo.png" 
        alt="KPI-360 Logo Top" 
        style={{ 
          maxHeight: isMobile ? "80px" : "120px", 
          marginBottom: isMobile ? "1rem" : "2rem" 
        }} 
      />

      <div style={{ 
        width: "100%", 
        maxWidth: "720px", 
        backgroundColor: "white", 
        padding: isMobile ? "1.5rem" : "2rem", 
        borderRadius: "1rem", 
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)", 
        flexGrow: 1, 
        display: "flex", 
        flexDirection: "column",
        position: "relative"
      }}>
        {step === 0 && (
          <div style={{ textAlign: "center" }}>
            <h1 style={{ 
              fontSize: isMobile ? "1.5rem" : "1.875rem", 
              fontWeight: "600", 
              marginBottom: "1rem" 
            }}>
              {t('appTitle')}
            </h1>
            <p style={{ marginBottom: "1rem" }}>
              <strong>
                "{t('slogan')}"<br />
                {t('sloganSub')}
              </strong>
            </p>
            <p style={{ marginBottom: "1.5rem" }}>
              {t('description')}<br />
              {t('testInfo')}
            </p>
            <button 
              style={{ 
                width: "100%", 
                padding: "1rem", 
                fontSize: "1.125rem", 
                borderRadius: "0.75rem", 
                backgroundColor: "#4f46e5", 
                color: "white", 
                border: "none",
                touchAction: "manipulation" // 모바일 터치 최적화
              }} 
              onClick={handleStart}
            >
              {t('startButton')}
            </button>
            
            {/* 모바일에서는 언어 드롭다운으로 표시 */}
            <div style={{ position: 'relative', marginTop: '1.5rem' }}>
              <button
                onClick={() => setShowLanguageSelector(!showLanguageSelector)}
                style={{
                  padding: '0.75rem 1rem',
                  backgroundColor: '#f3f4f6',
                  color: '#111827',
                  border: '1px solid #e5e7eb',
                  borderRadius: '0.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  fontSize: '1rem',
                  touchAction: 'manipulation'
                }}
              >
                <span>{languages[i18n.language] || languages['en']}</span>
                <span>{showLanguageSelector ? '▲' : '▼'}</span>
              </button>
              {renderLanguageSelector()}
            </div>
          </div>
        )}

        {step === 1 && (
          <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <h2 style={{ fontSize: "1.25rem", fontWeight: "600" }}>{t('basicInfoTitle')}</h2>
            <input 
              name="full_name" 
              placeholder={t('fullName')} 
              onChange={handleInputChange} 
              style={{ 
                padding: "0.75rem", 
                borderRadius: "0.5rem", 
                border: "1px solid #ccc",
                fontSize: isMobile ? "16px" : "inherit" // iOS에서 확대 방지
              }} 
            />
            <input 
              name="region" 
              placeholder={t('region')} 
              onChange={handleInputChange} 
              style={{ 
                padding: "0.75rem", 
                borderRadius: "0.5rem", 
                border: "1px solid #ccc",
                fontSize: isMobile ? "16px" : "inherit"
              }} 
            />
            <input 
              name="email" 
              type="email"
              placeholder={t('email')} 
              onChange={handleInputChange} 
              style={{ 
                padding: "0.75rem", 
                borderRadius: "0.5rem", 
                border: "1px solid #ccc",
                fontSize: isMobile ? "16px" : "inherit"
              }} 
            />
            <input 
              name="nationality" 
              placeholder={t('nationality')} 
              onChange={handleInputChange} 
              style={{ 
                padding: "0.75rem", 
                borderRadius: "0.5rem", 
                border: "1px solid #ccc",
                fontSize: isMobile ? "16px" : "inherit"
              }} 
            />
            <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem", marginTop: "1rem" }}>
              <button 
                onClick={handleBackToIntro} 
                style={{ 
                  flex: 1, 
                  padding: "0.75rem", 
                  backgroundColor: "#9ca3af", 
                  color: "white", 
                  borderRadius: "0.5rem", 
                  border: "none",
                  minHeight: "44px", // 모바일 터치 영역 확보
                  touchAction: "manipulation"
                }}
              >
                {t('backButton')}
              </button>
              <button 
                onClick={() => setStep(2)} 
                style={{ 
                  flex: 1, 
                  padding: "0.75rem", 
                  backgroundColor: "#10b981", 
                  color: "white", 
                  borderRadius: "0.5rem", 
                  border: "none",
                  minHeight: "44px",
                  touchAction: "manipulation"
                }}
              >
                {t('nextButton')}
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <>
            <div style={{ 
              position: "sticky", 
              top: 0, 
              zIndex: 5, 
              backgroundColor: "#fff",
              padding: "0.5rem",
              borderRadius: "0.5rem",
              boxShadow: "0 1px 4px rgba(0,0,0,0.05)",
              marginBottom: "1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              <div style={{ fontWeight: "600", fontSize: "1rem" }}>
                ⏱ {formatTime(timer)}
              </div>
              <div style={{ fontSize: "0.9rem" }}>
                {currentQuestionIndex + 1} / {questionsData.length}
              </div>
            </div>
            
            {/* PC에서는 스크롤, 모바일에서는 슬라이드 */}
            <div 
              ref={contentRef}
              style={{ 
                flex: 1, 
                overflowY: isMobile ? "auto" : "auto", 
                paddingRight: "0.5rem",
                marginBottom: "1rem"
              }}
            >
              {isMobile ? (
                // 모바일 뷰 - 한 문항씩 표시
                <div>
                  {renderProgressBar()}
                  
                  {/* 현재 섹션 표시 */}
                  <div style={{ 
                    textAlign: "center", 
                    marginBottom: "0.5rem", 
                    marginTop: "0.5rem", 
                    fontWeight: "bold", 
                    color: "#1f2937",
                    backgroundColor: "#f3f4f6",
                    padding: "0.5rem",
                    borderRadius: "0.5rem"
                  }}>
                    {getSectionName(questionsData[currentQuestionIndex].section)} 
                    ({questionsData[currentQuestionIndex].section}) : 
                    {sectionInfo[questionsData[currentQuestionIndex].section]?.range}
                  </div>
                  
                  {/* 현재 문항 표시 */}
                  <div style={{ 
                    padding: "1rem", 
                    marginBottom: "1rem", 
                    backgroundColor: "#f3f4f6", 
                    borderRadius: "0.5rem" 
                  }}>
                    <p style={{ 
                      marginBottom: "1rem", 
                      fontWeight: "500",
                      fontSize: "1.1rem"
                    }}>
                      <strong>{currentQuestionIndex + 1}.</strong> {getQuestionText(questionsData[currentQuestionIndex])}
                    </p>
                    <div style={{ 
                      display: "flex", 
                      flexDirection: "column", 
                      gap: "0.75rem", 
                      marginTop: "1rem" 
                    }}>
                      {getCurrentResponseOptions().map((opt, i) => (
                        <label 
                          key={i} 
                          style={{ 
                            display: "flex", 
                            alignItems: "center", 
                            padding: "0.75rem",
                            backgroundColor: answers[questionsData[currentQuestionIndex].id] === i + 1 
                              ? "#e0e7ff" 
                              : "#ffffff",
                            border: "1px solid #e5e7eb",
                            borderRadius: "0.5rem",
                            gap: "0.75rem",
                            cursor: "pointer",
                            minHeight: "44px" // 모바일 터치 영역 확보
                          }}
                        >
                          <input
                            type="radio"
                            name={`q${questionsData[currentQuestionIndex].id}`}
                            value={i + 1}
                            onChange={() => handleAnswer(questionsData[currentQuestionIndex].id, i + 1)}
                            checked={answers[questionsData[currentQuestionIndex].id] === i + 1}
                            style={{ 
                              width: "20px", 
                              height: "20px", 
                              accentColor: "#4f46e5" 
                            }}
                          />
                          <span style={{ fontSize: "1rem" }}>{opt}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                  
                  {renderAnswerStatusIndicator()}
                </div>
              ) : (
                // PC 뷰 - 모든 문항 스크롤로 표시 (기존 방식)
                <div>
                  {questionsData.map((q, idx) => {
                    const isFirstOfSection = idx === 0 || questionsData[idx - 1].section !== q.section;
                    return (
                      <React.Fragment key={q.id}>
                        {isFirstOfSection && (
                          <div style={{ 
                            textAlign: "center", 
                            marginBottom: "0.5rem", 
                            marginTop: "1.5rem", 
                            fontWeight: "bold", 
                            color: "#1f2937" 
                          }}>
                            {getSectionName(q.section)} ({q.section}) : {sectionInfo[q.section]?.range}
                          </div>
                        )}
                        <div style={{ 
                          padding: "1rem", 
                          marginBottom: "1rem", 
                          backgroundColor: "#f3f4f6", 
                          borderRadius: "0.5rem" 
                        }}>
                          <p style={{ marginBottom: "0.5rem", fontWeight: "500" }}>
                            <strong>{idx + 1}.</strong> {getQuestionText(q)}
                          </p>
                          <div style={{ 
                            display: "flex", 
                            flexDirection: "column", 
                            gap: "0.5rem", 
                            marginTop: "0.5rem" 
                          }}>
                            {getCurrentResponseOptions().map((opt, i) => (
                              <label 
                                key={i} 
                                style={{ 
                                  display: "flex", 
                                  alignItems: "center", 
                                  gap: "0.5rem",
                                  padding: "0.5rem",
                                  backgroundColor: answers[q.id] === i + 1 ? "#e0e7ff" : "transparent",
                                  borderRadius: "0.25rem"
                                }}
                              >
                                <input
                                  type="radio"
                                  name={`q${q.id}`}
                                  value={i + 1}
                                  onChange={() => handleAnswer(q.id, i + 1)}
                                  checked={answers[q.id] === i + 1}
                                />
                                <span>{opt}</span>
                              </label>
                            ))}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
            </div>
            
            {/* 네비게이션 버튼 */}
            <div style={{ 
              display: "flex", 
              justifyContent: "space-between", 
              gap: "1rem", 
              marginTop: "auto",
              position: isMobile ? "sticky" : "relative",
              bottom: 0,
              backgroundColor: "white",
              padding: isMobile ? "0.5rem 0" : 0,
              borderTop: isMobile ? "1px solid #f3f4f6" : "none"
            }}>
              {isMobile ? (
                // 모바일 네비게이션 (이전, 다음, 제출)
                <>
                  <button 
                    onClick={handlePrevQuestion} 
                    disabled={currentQuestionIndex === 0}
                    style={{ 
                      flex: 1, 
                      padding: "0.75rem", 
                      backgroundColor: currentQuestionIndex === 0 ? "#e5e7eb" : "#9ca3af", 
                      color: currentQuestionIndex === 0 ? "#9ca3af" : "white",
                      borderRadius: "0.5rem", 
                      border: "none",
                      minHeight: "44px",
                      opacity: currentQuestionIndex === 0 ? 0.6 : 1,
                      touchAction: "manipulation"
                    }}
                  >
                    {t('prevButton')}
                  </button>
                  
                  {currentQuestionIndex === questionsData.length - 1 ? (
                    <button 
                      onClick={handleSubmit} 
                      style={{ 
                        flex: 2, 
                        padding: "0.75rem", 
                        backgroundColor: "#4f46e5", 
                        color: "white", 
                        borderRadius: "0.5rem", 
                        border: "none",
                        minHeight: "44px",
                        fontWeight: "600",
                        touchAction: "manipulation"
                      }}
                    >
                      {t('submitButton')}
                    </button>
                  ) : (
                    <button 
                      onClick={handleNextQuestion} 
                      style={{ 
                        flex: 2, 
                        padding: "0.75rem", 
                        backgroundColor: answers[questionsData[currentQuestionIndex].id] ? "#10b981" : "#4f46e5", 
                        color: "white", 
                        borderRadius: "0.5rem", 
                        border: "none",
                        minHeight: "44px",
                        touchAction: "manipulation"
                      }}
                    >
                      {t('nextButton')}
                    </button>
                  )}
                </>
              ) : (
                // PC 네비게이션 (뒤로, 제출)
                <>
                  <button 
                    onClick={() => setStep(1)} 
                    style={{ 
                      flex: 1, 
                      padding: "1rem", 
                      backgroundColor: "#9ca3af", 
                      color: "white", 
                      borderRadius: "0.5rem", 
                      border: "none" 
                    }}
                  >
                    {t('backButton')}
                  </button>
                  <button 
                    onClick={handleSubmit} 
                    style={{ 
                      flex: 1, 
                      padding: "1rem", 
                      backgroundColor: "#4f46e5", 
                      color: "white", 
                      borderRadius: "0.5rem", 
                      border: "none" 
                    }}
                  >
                    {t('submitButton')}
                  </button>
                </>
              )}
            </div>
          </>
        )}

        {step === 3 && (
          <div style={{ textAlign: "center" }}>
            <h2 style={{ 
              fontSize: isMobile ? "1.25rem" : "1.5rem", 
              fontWeight: "600", 
              color: "#10b981", 
              marginBottom: "1rem" 
            }}>
              {t('completedTitle')}
            </h2>
            <p>{t('completedMessage')}</p>
          </div>
        )}
      </div>

      {/* 데스크톱에서만 언어 선택 버튼 표시 (모바일에서는 드롭다운으로 통합) */}
      {!isMobile && step === 0 && (
        <div style={{ 
          display: "flex", 
          justifyContent: "center", 
          marginTop: "1rem", 
          gap: "0.5rem", 
          flexWrap: "wrap", 
          maxWidth: "80%" 
        }}>
          {Object.entries(languages).map(([code, name]) => (
            <button
              key={code}
              onClick={() => changeLanguage(code)}
              style={{
                padding: "0.5rem 1rem",
                backgroundColor: i18n.language === code ? "#4f46e5" : "#e5e7eb",
                color: i18n.language === code ? "white" : "black",
                border: "none",
                borderRadius: "0.5rem",
                margin: "0.25rem",
                fontSize: "0.9rem",
              }}
            >
              {name}
            </button>
          ))}
        </div>
      )}

      <footer style={{ 
        textAlign: "center", 
        fontSize: "0.875rem", 
        color: "#6b7280", 
        marginTop: "auto",
        padding: "1rem 0" 
      }}>
        ⓒ 2025 KPI-360 | The Korean Today Persona Inventory™ All rights reserved.
      </footer>
    </div>
  );
}