import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationKO from './locales/ko/translation.json';
import translationFR from './locales/fr/translation.json';
import translationES from './locales/es/translation.json';
import translationAR from './locales/ar/translation.json';
import translationPT from './locales/pt/translation.json';
import translationJA from './locales/ja/translation.json';
import translationTH from './locales/th/translation.json';
import translationTR from './locales/tr/translation.json';
import translationDE from './locales/de/translation.json';
import translationZHCN from './locales/zh-cn/translation.json';
import translationTL from './locales/tl/translation.json';
import translationNL from './locales/nl/translation.json';
import translationTK from './locales/tk/translation.json';
import translationKY from './locales/ky/translation.json';
import translationDV from './locales/dv/translation.json';
import translationAK from './locales/ak/translation.json';
import translationXH from './locales/xh/translation.json';
import translationZU from './locales/zu/translation.json';
import translationMI from './locales/mi/translation.json';
import translationHA from './locales/ha/translation.json';
import translationYO from './locales/yo/translation.json';
import translationNY from './locales/ny/translation.json';
import translationSI from './locales/si/translation.json';
import translationGU from './locales/gu/translation.json';
import translationML from './locales/ml/translation.json';
import translationKN from './locales/kn/translation.json';
import translationBS from './locales/bs/translation.json';
import translationCY from './locales/cy/translation.json';
import translationCA from './locales/ca/translation.json';
import translationEU from './locales/eu/translation.json';
import translationIS from './locales/is/translation.json';
import translationBE from './locales/be/translation.json';
import translationSL from './locales/sl/translation.json';
import translationSK from './locales/sk/translation.json';
import translationGA from './locales/ga/translation.json';
import translationDA from './locales/da/translation.json';
import translationUK from './locales/uk/translation.json';
import translationHE from './locales/he/translation.json';
import translationKA from './locales/ka/translation.json';
import translationMY from './locales/my/translation.json';
import translationUR from './locales/ur/translation.json';
import translationUZ from './locales/uz/translation.json';
import translationTG from './locales/tg/translation.json';
import translationHY from './locales/hy/translation.json';
import translationLT from './locales/lt/translation.json';
import translationET from './locales/et/translation.json';
import translationHR from './locales/hr/translation.json';
import translationMK from './locales/mk/translation.json';
import translationRW from './locales/rw/translation.json';
import translationNO from './locales/no/translation.json';
import translationTI from './locales/ti/translation.json';
import translationKK from './locales/kk/translation.json';
import translationBG from './locales/bg/translation.json';
import translationLV from './locales/lv/translation.json';
import translationCS from './locales/cs/translation.json';
import translationAZ from './locales/az/translation.json';
import translationMN from './locales/mn/translation.json';
import translationTO from './locales/to/translation.json';
import translationTN from './locales/tn/translation.json';
import translationAF from './locales/af/translation.json';
import translationST from './locales/st/translation.json';
import translationSV from './locales/sv/translation.json';
import translationPS from './locales/ps/translation.json';
import translationBN from './locales/bn/translation.json';
import translationHT from './locales/ht/translation.json';
import translationLB from './locales/lb/translation.json';
import translationSR from './locales/sr/translation.json';
import translationFA from './locales/fa/translation.json';
import translationHU from './locales/hu/translation.json';
import translationMG from './locales/mg/translation.json';
import translationFI from './locales/fi/translation.json';
import translationSQ from './locales/sq/translation.json';
import translationBM from './locales/bm/translation.json';
import translationRO from './locales/ro/translation.json';
import translationPL from './locales/pl/translation.json';
import translationID from './locales/id/translation.json';
import translationEL from './locales/el/translation.json';
import translationMS from './locales/ms/translation.json';
import translationZHTW from './locales/zh-tw/translation.json';
import translationSM from './locales/sm/translation.json';
import translationRU from './locales/ru/translation.json';
import translationVI from './locales/vi/translation.json';
import translationIT from './locales/it/translation.json';
import translationSW from './locales/sw/translation.json';
import translationHI from './locales/hi/translation.json';
import translationAM from './locales/am/translation.json';
import translationQU from './locales/qu/translation.json';
import translationDZ from './locales/dz/translation.json';
import translationSS from './locales/ss/translation.json';
import translationLI from './locales/li/translation.json';
import translationLA from './locales/la/translation.json';
import translationFJ from './locales/fj/translation.json';
import translationOR from './locales/or/translation.json';
import translationKU from './locales/ku/translation.json';
import translationMH from './locales/mh/translation.json';
import translationCH from './locales/ch/translation.json';
import translationSN from './locales/sn/translation.json';
import translationWO from './locales/wo/translation.json';
import translationSO from './locales/so/translation.json';
import translationBO from './locales/bo/translation.json';
import translationAY from './locales/ay/translation.json';

// 언어 리소스
const resources = {
  en: {
    translation: translationEN
  },
  ko: {
    translation: translationKO
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // 기본 언어
    fallbackLng: 'ko', // 번역이 없을 경우 한국어로 대체
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['querystring', 'navigator']
    }
  });

export default i18n;
