import React, { useState, useEffect } from 'react';
import Login from './Login';

function AdminPanel() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [resendStatus, setResendStatus] = useState({});

  useEffect(() => {
    // 로컬 스토리지에서 토큰 확인
    const token = localStorage.getItem('adminToken');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchSubmissions();
    }
  }, [isAuthenticated]);

  const fetchSubmissions = async () => {
    try {
      setLoading(true);
      const response = await fetch('http://localhost:4000/api/admin/submissions', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
        }
      });
      
      if (!response.ok) {
        throw new Error('데이터를 불러오는데 실패했습니다.');
      }
      
      const data = await response.json();
      setSubmissions(data);
      setError(null);
    } catch (err) {
      console.error('Error fetching submissions:', err);
      setError('데이터를 불러오는데 문제가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleResend = async (id) => {
    try {
      setResendStatus(prev => ({ ...prev, [id]: 'loading' }));
      
      const response = await fetch(`http://localhost:4000/api/admin/resend-report/${id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
        }
      });
      
      if (!response.ok) {
        throw new Error('리포트 재발송 실패');
      }
      
      setResendStatus(prev => ({ ...prev, [id]: 'success' }));
      
      // 3초 후 상태 초기화
      setTimeout(() => {
        setResendStatus(prev => {
          const newStatus = { ...prev };
          delete newStatus[id];
          return newStatus;
        });
      }, 3000);
      
    } catch (err) {
      console.error('Error resending report:', err);
      setResendStatus(prev => ({ ...prev, [id]: 'error' }));
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    setIsAuthenticated(false);
  };

  if (!isAuthenticated) {
    return <Login onLogin={() => setIsAuthenticated(true)} />;
  }

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h1>KPI-360 관리자 패널</h1>
        <button 
          onClick={handleLogout}
          style={{ 
            padding: '8px 16px',
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          로그아웃
        </button>
      </div>
      
      <button 
        onClick={fetchSubmissions}
        style={{ 
          padding: '8px 16px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          marginBottom: '20px',
          cursor: 'pointer'
        }}
      >
        데이터 새로고침
      </button>
      
      {error && <p style={{ color: 'red' }}>{error}</p>}
      
      {loading ? (
        <p>데이터를 불러오는 중...</p>
      ) : (
        <div style={{ overflowX: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ backgroundColor: '#f2f2f2' }}>
                <th style={{ border: '1px solid #ddd', padding: '12px', textAlign: 'left' }}>ID</th>
                <th style={{ border: '1px solid #ddd', padding: '12px', textAlign: 'left' }}>이름</th>
                <th style={{ border: '1px solid #ddd', padding: '12px', textAlign: 'left' }}>이메일</th>
                <th style={{ border: '1px solid #ddd', padding: '12px', textAlign: 'left' }}>지역</th>
                <th style={{ border: '1px solid #ddd', padding: '12px', textAlign: 'left' }}>국가</th>
                <th style={{ border: '1px solid #ddd', padding: '12px', textAlign: 'left' }}>IP 주소</th>
                <th style={{ border: '1px solid #ddd', padding: '12px', textAlign: 'left' }}>제출일시</th>
                <th style={{ border: '1px solid #ddd', padding: '12px', textAlign: 'left' }}>액션</th>
              </tr>
            </thead>
            <tbody>
              {submissions.length > 0 ? (
                submissions.map(submission => (
                  <tr key={submission.id}>
                    <td style={{ border: '1px solid #ddd', padding: '12px' }}>{submission.id}</td>
                    <td style={{ border: '1px solid #ddd', padding: '12px' }}>{submission.full_name}</td>
                    <td style={{ border: '1px solid #ddd', padding: '12px' }}>{submission.email}</td>
                    <td style={{ border: '1px solid #ddd', padding: '12px' }}>{submission.region}</td>
                    <td style={{ border: '1px solid #ddd', padding: '12px' }}>{submission.nationality}</td>
                    <td style={{ border: '1px solid #ddd', padding: '12px' }}>{submission.ip_address || 'N/A'}</td>
                    <td style={{ border: '1px solid #ddd', padding: '12px' }}>
                      {new Date(submission.created_at).toLocaleString()}
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '12px' }}>
                      <button
                        onClick={() => handleResend(submission.id)}
                        disabled={resendStatus[submission.id] === 'loading'}
                        style={{
                          padding: '6px 12px',
                          backgroundColor: 
                            resendStatus[submission.id] === 'loading' ? '#ccc' :
                            resendStatus[submission.id] === 'success' ? '#4CAF50' :
                            resendStatus[submission.id] === 'error' ? '#f44336' : '#2196F3',
                          color: 'white',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: resendStatus[submission.id] === 'loading' ? 'not-allowed' : 'pointer'
                        }}
                      >
                        {resendStatus[submission.id] === 'loading' ? '처리 중...' :
                         resendStatus[submission.id] === 'success' ? '성공!' :
                         resendStatus[submission.id] === 'error' ? '오류' : '리포트 재발송'}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" style={{ border: '1px solid #ddd', padding: '12px', textAlign: 'center' }}>
                    제출된 데이터가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default AdminPanel;
