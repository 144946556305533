// 응답 옵션 데이터
const responseOptions = {
  'ko': ['전혀 그렇지 않다', '그렇지 않다', '약간 그렇다', '그렇다', '매우 그렇다'],
  'en': ['Strongly Disagree', 'Disagree', 'Somewhat Agree', 'Agree', 'Strongly Agree'],
  'fr': ['Pas du tout', 'Non', 'Un peu comme ça', 'Oui', 'Tout à fait'],
  'es': ['Totalmente en desacuerdo', 'En desacuerdo', 'Algo de acuerdo', 'De acuerdo', 'Totalmente de acuerdo'],
  'de': ['Stimme überhaupt nicht zu', 'Stimme nicht zu', 'Stimme etwas zu', 'Stimme zu', 'Stimme voll zu'],
  'ja': ['全くそう思わない', 'そう思わない', '少しそう思う', 'そう思う', '非常にそう思う'],
  'zh-cn': ['完全不同意', '不同意', '有点同意', '同意', '非常同意'],
  'zh-tw': ['完全不同意', '不同意', '有點同意', '同意', '非常同意'],
  'ru': ['Категорически не согласен', 'Не согласен', 'Отчасти согласен', 'Согласен', 'Полностью согласен'],
  'pt': ['Discordo totalmente', 'Discordo', 'Concordo um pouco', 'Concordo', 'Concordo totalmente'],
  'ar': ['لا أوافق بشدة', 'لا أوافق', 'موافق إلى حد ما', 'موافق', 'موافق بشدة'],
  'it': ['Fortemente in disaccordo', 'In disaccordo', 'Parzialmente d\'accordo', 'D\'accordo', 'Fortemente d\'accordo'],
  'nl': ['Sterk oneens', 'Oneens', 'Enigszins mee eens', 'Mee eens', 'Sterk mee eens'],
  'vi': ['Hoàn toàn không đồng ý', 'Không đồng ý', 'Hơi đồng ý', 'Đồng ý', 'Hoàn toàn đồng ý'],
  'tr': ['Kesinlikle katılmıyorum', 'Katılmıyorum', 'Biraz katılıyorum', 'Katılıyorum', 'Kesinlikle katılıyorum'],
  'pl': ['Zdecydowanie się nie zgadzam', 'Nie zgadzam się', 'Częściowo się zgadzam', 'Zgadzam się', 'Zdecydowanie się zgadzam'],
  'id': ['Sangat tidak setuju', 'Tidak setuju', 'Agak setuju', 'Setuju', 'Sangat setuju'],
  'th': ['ไม่เห็นด้วยอย่างยิ่ง', 'ไม่เห็นด้วย', 'เห็นด้วยบางส่วน', 'เห็นด้วย', 'เห็นด้วยอย่างยิ่ง'],
  'hi': ['पूरी तरह असहमत', 'असहमत', 'कुछ हद तक सहमत', 'सहमत', 'पूरी तरह सहमत'],
  'sv': ['Håller inte alls med', 'Håller inte med', 'Håller delvis med', 'Håller med', 'Håller helt med'],
  'af': ['Stem glad nie saam nie', 'Stem nie saam nie', 'Stem ietwat saam', 'Stem saam', 'Stem heeltemal saam'],
  'am': ['በጭራሽ አልስማማም', 'አልስማማም', 'በአንፃፃፃ እስማማለሁ', 'እስማማለሁ', 'በጭራሽ እስማማለሁ'],
  'ay': ['Janiwa ch\'amañatakiti', 'Janiwa', 'Jisk\'a ch\'amañataki', 'Ch\'amañataki', 'Jach\'a ch\'amañataki'],
  'az': ['Qətiyyən razı deyiləm', 'Razı deyiləm', 'Qismən razıyam', 'Razıyam', 'Tamamilə razıyam'],
  'be': ['Катэгарычна не згодны', 'Не згодны', 'Часткова згодны', 'Згодны', 'Поўнасцю згодны'],
  'bg': ['Категорично не съм съгласен', 'Не съм съгласен', 'До известна степен съм съгласен', 'Съгласен съм', 'Напълно съгласен'],
  'bm': ['N bɛ se ka dɔɔnin', 'N bɛ se ka dɔɔnin dɔɔnin', 'N bɛ se ka dɔɔnin', 'N bɛ se ka', 'N bɛ se ka kɛlɛba'],
  'bn': ['একেবারেই অসম্মতি', 'অসম্মতি', 'কিছুটা সম্মতি', 'সম্মতি', 'সম্পূর্ণ সম্মতি'],
  'bo': ['མི་མཐུན་པ་ཡོད།', 'མི་མཐུན་པ།', 'གནད་དོན་གཅིག་མཐུན་པ།', 'མཐུན་པ།', 'ཡོད་མཐུན་པ།'],
  'ca': ['Totalment en desacord', 'En desacord', 'Parcialment d\'acord', 'D\'acord', 'Totalment d\'acord'],
  'ch': ['Ti nahongge', 'Ti nahongge', 'Ti nahongge', 'Ti nahongge', 'Ti nahongge'],
  'cs': ['Rozhodně nesouhlasím', 'Nesouhlasím', 'Částečně souhlasím', 'Souhlasím', 'Rozhodně souhlasím'],
  'da': ['Helt uenig', 'Uenig', 'Delvist enig', 'Enig', 'Helt enig'],
  'dz': ['མི་མཐུན་པ་ཡོད།', 'མི་མཐུན་པ།', 'གནད་དོན་གཅིག་མཐུན་པ།', 'མཐུན་པ།', 'ཡོད་མཐུན་པ།'],
  'el': ['Διαφωνώ απόλυτα', 'Διαφωνώ', 'Μερικώς συμφωνώ', 'Συμφωνώ', 'Συμφωνώ απόλυτα'],
  'et': ['Üldse ei nõustu', 'Ei nõustu', 'Osaliselt nõustun', 'Nõustun', 'Täiesti nõustun'],
  'eu': ['Erabat desadostasuna', 'Desadostasuna', 'Pixka bat ados', 'Ados', 'Erabat ados'],
  'fa': ['کاملاً مخالفم', 'مخالفم', 'تا حدودی موافقم', 'موافقم', 'کاملاً موافقم'],
  'fi': ['Täysin eri mieltä', 'Eri mieltä', 'Osittain samaa mieltä', 'Samaa mieltä', 'Täysin samaa mieltä'],
  'fj': ['Sega ni duavata vakadua', 'Sega ni duavata', 'Vakadua vakalailai', 'Duavata', 'Duavata vakalevu'],
  'ga': ['Ní aontaím go hiomlán', 'Ní aontaím', 'Aontaím go páirteach', 'Aontaím', 'Aontaím go hiomlán'],
  'he': ['לא מסכים בכלל', 'לא מסכים', 'מסכים חלקית', 'מסכים', 'מסכים לחלוטין'],
  'hr': ['U potpunosti se ne slažem', 'Ne slažem se', 'Djelomično se slažem', 'Slažem se', 'U potpunosti se slažem'],
  'ht': ['Pa dakò ditou', 'Pa dakò', 'Dakò pasyèlman', 'Dakò', 'Konplètman dakò'],
  'hu': ['Egyáltalán nem értek egyet', 'Nem értek egyet', 'Részben egyetértek', 'Egyetértek', 'Teljesen egyetértek'],
  'hy': ['Բացարձակապես համաձայն չեմ', 'Համաձայն չեմ', 'Մասամբ համաձայն եմ', 'Համաձայն եմ', 'Բացարձակապես համաձայն եմ'],
  'is': ['Algerlega ósammála', 'Ósammála', 'Að hluta sammála', 'Sammála', 'Algerlega sammála'],
  'ka': ['სრულიად არ ვეთანხმები', 'არ ვეთანხმები', 'ნაწილობრივ ვეთანხმები', 'ვეთანხმები', 'სრულიად ვეთანხმები'],
  'kk': ['Мүлдем келіспеймін', 'Келіспеймін', 'Жартылай келісемін', 'Келісемін', 'Толық келісемін'],
  'af': ['Stem glad nie saam nie', 'Stem nie saam nie', 'Stem ietwat saam', 'Stem saam', 'Stem heeltemal saam'],
  'am': ['በጭራሽ አልስማማም', 'አልስማማም', 'በአንፃፃፃ እስማማለሁ', 'እስማማለሁ', 'በጭራሽ እስማማለሁ'],
  'ay': ['Janiwa ch\'amañatakiti', 'Janiwa', 'Jisk\'a ch\'amañataki', 'Ch\'amañataki', 'Jach\'a ch\'amañataki'],
  'az': ['Qətiyyən razı deyiləm', 'Razı deyiləm', 'Qismən razıyam', 'Razıyam', 'Tamamilə razıyam'],
  'be': ['Катэгарычна не згодны', 'Не згодны', 'Часткова згодны', 'Згодны', 'Поўнасцю згодны'],
  'bg': ['Категорично не съм съгласен', 'Не съм съгласен', 'До известна степен съм съгласен', 'Съгласен съм', 'Напълно съгласен'],
  'bm': ['N bɛ se ka dɔɔnin', 'N bɛ se ka dɔɔnin dɔɔnin', 'N bɛ se ka dɔɔnin', 'N bɛ se ka', 'N bɛ se ka kɛlɛba'],
  'bn': ['একেবারেই অসম্মতি', 'অসম্মতি', 'কিছুটা সম্মতি', 'সম্মতি', 'সম্পূর্ণ সম্মতি'],
  'bo': ['མི་མཐུན་པ་ཡོད།', 'མི་མཐུན་པ།', 'གནད་དོན་གཅིག་མཐུན་པ།', 'མཐུན་པ།', 'ཡོད་མཐུན་པ།'],
  'ca': ['Totalment en desacord', 'En desacord', 'Parcialment d\'acord', 'D\'acord', 'Totalment d\'acord'],
  'ch': ['Ti nahongge', 'Ti nahongge', 'Ti nahongge', 'Ti nahongge', 'Ti nahongge'],
  'cs': ['Rozhodně nesouhlasím', 'Nesouhlasím', 'Částečně souhlasím', 'Souhlasím', 'Rozhodně souhlasím'],
  'da': ['Helt uenig', 'Uenig', 'Delvist enig', 'Enig', 'Helt enig'],
  'ku': ['Bi tevahî ne razî me', 'Ne razî me', 'Hinek razî me', 'Razî me', 'Bi tevahî razî me'],
  'la': ['Valde dissentio', 'Dissentio', 'Aliquantulum assentior', 'Assentior', 'Valde assentior'],
  'lb': ['Absolut net averstan', 'Net averstan', 'E bëssen averstan', 'Averstan', 'Voll averstan'],
  'li': ['Helemaol neet ens', 'Neet ens', 'Ein bietje ens', 'Ens', 'Helemaol ens'],
  'lt': ['Visiškai nesutinku', 'Nesutinku', 'Iš dalies sutinku', 'Sutinku', 'Visiškai sutinku'],
  'lv': ['Pilnīgi nepiekrītu', 'Nepiekrītu', 'Daļēji piekrītu', 'Piekrītu', 'Pilnīgi piekrītu'],
  'mg': ['Tsy manaiky tanteraka', 'Tsy manaiky', 'Manaiky kely', 'Manaiky', 'Manaiky tanteraka'],
  'mh': ['Iakwe in aō', 'Iakwe', 'Iakwe wōt', 'Iakwe eok', 'Iakwe in aō eok'],
  'mi': ['Kāore rawa e whakaae', 'Kāore e whakaae', 'Āhua whakaae', 'Whakaae', 'Whakaae rawa'],
  'mk': ['Воопшто не се согласувам', 'Не се согласувам', 'Делумно се согласувам', 'Се согласувам', 'Целосно се согласувам'],
  'ml': ['മുഴുവനും സമ്മതിക്കുന്നില്ല', 'സമ്മതിക്കുന്നില്ല', 'ചെറിയ സമ്മതം', 'സമ്മതം', 'മുഴുവനും സമ്മതം'],
  'mn': ['Огт санал нийлэхгүй байна', 'Санал нийлэхгүй байна', 'Бага зэрэг санал нийлж байна', 'Санал нийлж байна', 'Бүрэн санал нийлж байна'],
  'ms': ['Sangat tidak setuju', 'Tidak setuju', 'Agak setuju', 'Setuju', 'Sangat setuju'],
  'my': ['လုံးဝသဘောမတူပါ', 'သဘောမတူပါ', 'အနည်းငယ်သဘောတူပါ', 'သဘောတူပါ', 'လုံးဝသဘောတူပါ'],
  'no': ['Helt uenig', 'Uenig', 'Delvis enig', 'Enig', 'Helt enig'],
  'or': ['ସମ୍ପୂର୍ଣ୍ଣ ଅସ୍ଵୀକୃତି', 'ଅସ୍ଵୀକୃତି', 'କିଛି ଅଂଶରେ ସ୍ଵୀକୃତି', 'ସ୍ଵୀକୃତି', 'ସମ୍ପୂର୍ଣ୍ଣ ସ୍ଵୀକୃତି'],
  'ps': ['په بشپړ ډول موافق نه یم', 'موافق نه یم', 'تر یوه حده موافق یم', 'موافق یم', 'په بشپړ ډول موافق یم'],
  'qu': ['Mana allinchu', 'Mana', 'Ancha allinchu', 'Allin', 'Ancha allin'],
  'ro': ['Dezacord total', 'Dezacord', 'Parțial de acord', 'De acord', 'De acord total'],
  'rw': ['Ntabwo nemeranya na gato', 'Ntabwo nemeranya', 'Nemeranya gato', 'Nemeranya', 'Nemeranya rwose'],
  'sk': ['Úplne nesúhlasím', 'Nesúhlasím', 'Čiastočne súhlasím', 'Súhlasím', 'Úplne súhlasím'],
  'sl': ['Popolnoma se ne strinjam', 'Se ne strinjam', 'Delno se strinjam', 'Se strinjam', 'Popolnoma se strinjam'],
  'sm': ['Leai se malie', 'Leai', 'Fa\'aletonu', 'Ioe', 'Malie tele'],
  'sn': ['Handibvumirane zvachose', 'Handibvumirane', 'Ndino bvumirana zvishoma', 'Ndino bvumirana', 'Ndino bvumirana zvachose'],
  'so': ['Si buuxda uma oggoli', 'Uma oggoli', 'Waxoogaa waan oggolahay', 'Waan oggolahay', 'Si buuxda waan oggolahay'],
  'sq': ['Nuk pajtohem fare', 'Nuk pajtohem', 'Pajtohem pjesërisht', 'Pajtohem', 'Plotësisht pajtohem'],
  'sr': ['У потпуности се не слажем', 'Не слажем се', 'Делимично се слажем', 'Слажем се', 'У потпуности се слажем'],
  'ss': ['Angivumelani nakancane', 'Angivumelani', 'Ngivumelana kancane', 'Ngivumelana', 'Ngivumelana ngokuphelele'],
  'st': ['Ha ke lumellane ho hang', 'Ha ke lumellane', 'Ke lumellana hanyane', 'Ke lumellana', 'Ke lumellana ka botlalo'],
  'sw': ['Sikubaliani kabisa', 'Sikubaliani', 'Nakubaliana kiasi', 'Nakubaliana', 'Nakubaliana kabisa'],
  'tg': ['Тамоман розӣ нестам', 'Розӣ нестам', 'Қисман розӣ ҳастам', 'Розӣ ҳастам', 'Тамоман розӣ ҳастам'],
  'ti': ['ከማንኛውም ጋር አልስማማም', 'አልስማማም', 'በአንፃፃፃ እስማማለሁ', 'እስማማለሁ', 'በጭራሽ እስማማለሁ'],
  'af': ['Stem glad nie saam nie', 'Stem nie saam nie', 'Stem ietwat saam', 'Stem saam', 'Stem heeltemal saam'],
  'am': ['በጭራሽ አልስማማም', 'አልስማማም', 'በአንፃፃፃ እስማማለሁ', 'እስማማለሁ', 'በጭራሽ እስማማለሁ'],
  'ay': ['Janiwa ch\'amañatakiti', 'Janiwa', 'Jisk\'a ch\'amañataki', 'Ch\'amañataki', 'Jach\'a ch\'amañataki'],
  'az': ['Qətiyyən razı deyiləm', 'Razı deyiləm', 'Qismən razıyam', 'Razıyam', 'Tamamilə razıyam'],
  'be': ['Катэгарычна не згодны', 'Не згодны', 'Часткова згодны', 'Згодны', 'Поўнасцю згодны'],
  'bg': ['Категорично не съм съгласен', 'Не съм съгласен', 'До известна степен съм съгласен', 'Съгласен съм', 'Напълно съгласен'],
  'bm': ['N bɛ se ka dɔɔnin', 'N bɛ se ka dɔɔnin dɔɔnin', 'N bɛ se ka dɔɔnin', 'N bɛ se ka', 'N bɛ se ka kɛlɛba'],
  'bn': ['একেবারেই অসম্মতি', 'অসম্মতি', 'কিছুটা সম্মতি', 'সম্মতি', 'সম্পূর্ণ সম্মতি'],
  'bo': ['མི་མཐུན་པ་ཡོད།', 'མི་མཐུན་པ།', 'གནད་དོན་གཅིག་མཐུན་པ།', 'མཐུན་པ།', 'ཡོད་མཐུན་པ།'],
  'ca': ['Totalment en desacord', 'En desacord', 'Parcialment d\'acord', 'D\'acord', 'Totalment d\'acord'],
  'ch': ['Ti nahongge', 'Ti nahongge', 'Ti nahongge', 'Ti nahongge', 'Ti nahongge'],
  'cs': ['Rozhodně nesouhlasím', 'Nesouhlasím', 'Částečně souhlasím', 'Souhlasím', 'Rozhodně souhlasím'],
  'da': ['Helt uenig', 'Uenig', 'Delvist enig', 'Enig', 'Helt enig'],
  'dz': ['མི་མཐུན་པ་ཡོད།', 'མི་མཐུན་པ།', 'གནད་དོན་གཅིག་མཐུན་པ།', 'མཐུན་པ།', 'ཡོད་མཐུན་པ།'],
  'el': ['Διαφωνώ απόλυτα', 'Διαφωνώ', 'Μερικώς συμφωνώ', 'Συμφωνώ', 'Συμφωνώ απόλυτα'],
  'et': ['Üldse ei nõustu', 'Ei nõustu', 'Osaliselt nõustun', 'Nõustun', 'Täiesti nõustun'],
  'eu': ['Erabat desadostasuna', 'Desadostasuna', 'Pixka bat ados', 'Ados', 'Erabat ados'],
  'fa': ['کاملاً مخالفم', 'مخالفم', 'تا حدودی موافقم', 'موافقم', 'کاملاً موافقم'],
  'fi': ['Täysin eri mieltä', 'Eri mieltä', 'Osittain samaa mieltä', 'Samaa mieltä', 'Täysin samaa mieltä'],
  'fj': ['Sega ni duavata vakadua', 'Sega ni duavata', 'Vakadua vakalailai', 'Duavata', 'Duavata vakalevu'],
  'ga': ['Ní aontaím go hiomlán', 'Ní aontaím', 'Aontaím go páirteach', 'Aontaím', 'Aontaím go hiomlán'],
  'he': ['לא מסכים בכלל', 'לא מסכים', 'מסכים חלקית', 'מסכים', 'מסכים לחלוטין'],
  'hr': ['U potpunosti se ne slažem', 'Ne slažem se', 'Djelomično se slažem', 'Slažem se', 'U potpunosti se slažem'],
  'ht': ['Pa dakò ditou', 'Pa dakò', 'Dakò pasyèlman', 'Dakò', 'Konplètman dakò'],
  'hu': ['Egyáltalán nem értek egyet', 'Nem értek egyet', 'Részben egyetértek', 'Egyetértek', 'Teljesen egyetértek'],
  'hy': ['Բացարձակապես համաձայն չեմ', 'Համաձայն չեմ', 'Մասամբ համաձայն եմ', 'Համաձայն եմ', 'Բացարձակապես համաձայն եմ'],
  'is': ['Algerlega ósammála', 'Ósammála', 'Að hluta sammála', 'Sammála', 'Algerlega sammála'],
  'ka': ['სრულიად არ ვეთანხმები', 'არ ვეთანხმები', 'ნაწილობრივ ვეთანხმები', 'ვეთანხმები', 'სრულიად ვეთანხმები'],
  'kk': ['Мүлдем келіспеймін', 'Келіспеймін', 'Жартылай келісемін', 'Келісемін', 'Толық келісемін'],
  // 다른 언어는 번역 후 추가할 수 있습니다.
};

export default responseOptions;
