// 섹션 정보 다국어 지원
const sectionInfo = {
  A: { 
    name: {
      "ko": "조직 윤리와 책임감",
      "en": "Organizational Ethics and Responsibility",
      "fr": "Éthique et responsabilité organisationnelles",
      "es": "Ética y responsabilidad organizacional",
      "de": "Organisationsethik und Verantwortung",
      "ja": "組織の倫理と責任",
      "zh-cn": "组织伦理与责任",
      "ru": "Организационная этика и ответственность",
      "ar": "أخلاقيات ومسؤولية المنظمة",
      "pt": "Ética e Responsabilidade Organizacional",
      "th": "จริยธรรมและความรับผิดชอบขององค์กร",
      "tr": "Organizasyonel Etik ve Sorumluluk",
      "tl": "Etika at Pananagutan ng Organisasyon",
      "nl": "Organisatie-ethiek en Verantwoordelijkheid",
      "tk": "Guramanyň Etikasy we Jogapkärçiligi",
      "ky": "Уюмдун этикасы жана жоопкерчилиги",
      "dv": "އޮގަނައިޒޭޝަނަލް އެތިކްސް އެންޑް ރިސްޕޮންސިބިލިޓީ",
      "ak": "Ahofadi ne Nneyɛe Ahofadi",
      "xh": "Ukuziphatha Nokuziphendulela Kwenhlangano",
      "zu": "Ukuziphatha Nokuziphendulela Kwenhlangano",
      "mi": "Ngā Tikanga me te Haepapa o te Whakahaere",
      "ha": "Ƙa'idar ɗabi'a da Alhakin Ƙungiya",
      "yo": "Ìwà Omolúàbí àti Ojùṣe Ilé-Ìṣọ̀kan",
      "ny": "Makhalidwe Abwino ndi Udindo wa Bungwe",
      "si": "සංවිධානීය නීතිමය හා වගකීම්",
      "gu": "સંસ્થાકીય નૈતિકતા અને જવાબદારી",
      "ml": "സംഘടനാത്മക നൈതികതയും ഉത്തരവാദിത്വവും",
      "kn": "ಸಂಸ್ಥೆಯ ನೈತಿಕತೆ ಮತ್ತು ಹೊಣೆಗಾರಿಕೆ",
      "bs": "Organizacijska etika i odgovornost",
      "cy": "Moeseg a Chyfrifoldeb Sefydliadol",
      "ca": "Ètica i Responsabilitat Organitzacional",
      "eu": "Antolakuntza Etika eta Erantzukizuna",
      "is": "Skipulagsheiðarleiki og Ábyrgð",
      "be": "Арганізацыйная этыка і адказнасць",
      "sl": "Organizacijska etika in odgovornost",
      "sk": "Organizačná etika a zodpovednosť",
      "ga": "Eitic agus Freagracht Eagraíochtúil",
      "da": "Organisatorisk etik og ansvar",
      "uk": "Організаційна етика та відповідальність",
      "he": "אתיקה ארגונית ואחריות",
      "ka": "ორგანიზაციული ეთიკა და პასუხისმგებლობა",
      "my": "အဖွဲ့အစည်း၏ ယဉ်ကျေးမှုနှင့် တာဝန်ယူမှု",
      "ur": "تنظیمی اخلاقیات اور ذمہ داری",
      "uz": "Tashkiliy etika va mas'uliyat",
      "tg": "Ахлоқи ташкилотӣ ва масъулият",
      "hy": "Կազմակերպական էթիկա և պատասխանատվություն",
      "lt": "Organizacinė etika ir atsakomybė",
      "et": "Organisatsiooni eetika ja vastutus",
      "hr": "Organizacijska etika i odgovornost",
      "mk": "Организациска етика и одговорност",
      "rw": "Imyitwarire n'inshingano by'umuryango",
      "no": "Organisatorisk etikk og ansvar",
      "ti": "የድርጅት ሥነ-ምግባር እና ኃላፊነት",
      "kk": "Ұйымдастырушылық этика және жауапкершілік",
      "bg": "Организационна етика и отговорност",
      "lv": "Organizācijas ētika un atbildība",
      "cs": "Organizační etika a odpovědnost",
      "az": "Təşkilati Etika və Məsuliyyət",
      "mn": "Байгууллагын ёс зүй ба хариуцлага",
      "to": "Faka'apa'apa mo e Fatongia 'a e Kautaha",
      "tn": "Maitlamo le Boikarabelo jwa Mokgatlho",
      "af": "Organisatoriese Etiek en Verantwoordelikheid",
      "st": "Boitšoaro le Boikarabello ba Mokhatlo",
      "sv": "Organisatorisk etik och ansvar",
      "ps": "سازماني اخلاق او مسؤلیت",
      "bn": "প্রতিষ্ঠানের নৈতিকতা এবং দায়িত্ব",
      "ht": "Etik ak Responsablite Òganizasyonèl",
      "lb": "Organisatoresch Ethik a Verantwortung",
      "sr": "Организациона етика и одговорност",
      "fa": "اخلاق سازمانی و مسئولیت",
      "hu": "Szervezeti etika és felelősség",
      "mg": "Etika sy Andraikitra Ara-pikambanana",
      "fi": "Organisaation etiikka ja vastuu",
      "sq": "Etika dhe Përgjegjësia Organizative",
      "bm": "Etika ni Jatigiya Kɔrɔ",
      "ro": "Etică și Responsabilitate Organizațională",
      "pl": "Etyka i Odpowiedzialność Organizacyjna",
      "id": "Etika dan Tanggung Jawab Organisasi",
      "el": "Οργανωτική Ηθική και Ευθύνη",
      "ms": "Etika dan Tanggungjawab Organisasi",
      "zh-tw": "組織倫理與責任",
      "am": "የድርጅት ሥነ-ምግባር እና ኃላፊነት",
      "ay": "Organisasyonal Ethics ukhamarakiwa",
      "bo": "ཚོགས་ཆུང་སྤྱོད་ལམ་དང་འགན་འཁུར།",
      "ch": "Etika yan Organización yan Responsabilidad",
      "dz": "ཚོགས་ཆུང་སྤྱོད་ལམ་དང་འགན་འཁུར།",
      "fj": "iTovo Vakaturaga kei na Itavi Vakacakacaka",
      "ku": "Rêzêkî Organîzasyonê û Bêbextiyê",
      "la": "Ethica Organisationalis et Responsabilitas",
      "li": "Organisatorische Ethiek en Verantwoordelijkheid",
      "mh": "Jinen Eo an Organization im Responsibility",
      "or": "ସଂଗଠନ ନୈତିକତା ଏବଂ ଦାୟିତ୍ୱ",
      "qu": "Organisasyunpa Ethics wan Responsabilidad",
      "sn": "Tsika dzeSangano uye Basa",
      "so": "Anshaxa Ururka iyo Mas'uuliyadda",
      "ss": "Kutfutfukisa Kwemphakatsi ne Umsebenti",
      "wo": "Ethique ak Mënsetalu Organisation",
      "sm": "Amiotonu ma le Tiute Tauave a le Faalapotopotoga",
      "vi": "Đạo đức và Trách nhiệm Tổ chức",
      "it": "Etica e Responsabilità Organizzativa",
      "sw": "Maadili na Uwajibikaji wa Shirika",
      "hi": "संगठनात्मक नैतिकता और ज़िम्मेदारी",      
      // 다른 언어는 번역 후 추가
    }, 
    range: "1–45" 
  },
  B: { 
    name: {
      "ko": "정서 안정성",
      "en": "Emotional Stability",
      "fr": "Stabilité émotionnelle",
      "es": "Estabilidad emocional",
      "de": "Emotionale Stabilität",
      "ja": "感情の安定性",
      "zh": "情绪稳定性",
      "ru": "Эмоциональная стабильность",
      "ar": "الاستقرار العاطفي",
      "pt": "Estabilidade Emocional",
      "th": "ความมั่นคงทางอารมณ์",
      "tr": "Duygusal Denge",
      "zh-cn": "情绪稳定性",
      "tl": "Emosyonal na Katatagan",
      "nl": "Emotionele Stabiliteit",
      "tk": "Duýgudaýy berklik",
      "ky": "Эмоциялык туруктуулук",
      "dv": "އިންސާންގެ ސިކުރުބަސް",
      "ak": "Adwuma a ɛyɛ anigye ne dwoodwoo",
      "xh": "Uzolile ngokweemvakalelo",
      "zu": "Ukuzinzile Ngokwengqondo",
      "mi": "Te Mārire Hinengaro",
      "ha": "Daidaituwar motsin zuciya",
      "yo": "Ìdákẹ́jẹ́ Ọkàn",
      "ny": "Bata la Mtima Lokhazikika",
      "si": "භාවනාත්මක ස්ථාවරභාවය",
      "gu": "ભાવનાત્મક સ્થિરતા",
      "ml": "ഭാവനാത്മക സ്ഥിരത",
      "kn": "ಭಾವೋದ್ವೇಗ ಸ್ಥಿರತೆ",
      "bs": "Emocionalna stabilnost",
      "cy": "Sefydlogrwydd Emosiynol",
      "ca": "Estabilitat Emocional",
      "eu": "Egonkortasun emozionala",
      "is": "Tilfinningalegur stöðugleiki",
      "be": "Эмацыянальная стабільнасць",
      "sl": "Čustvena stabilnost",
      "sk": "Emočná stabilita",
      "ga": "Cobhsaíocht Mothúchánach",
      "da": "Følelsesmæssig stabilitet",
      "uk": "Емоційна стабільність",
      "he": "יציבות רגשית",
      "ka": "ემოციური სტაბილურობა",
      "my": "ခံစားမှုတည်ငြိမ်မှု",
      "ur": "جذباتی استحکام",
      "uz": "Hissiy barqarorlik",
      "tg": "Суботкорӣ ҳиссӣ",
      "hy": "Զգացմունքային կայունություն",
      "lt": "Emocinė stabilumas",
      "et": "Emotsionaalne stabiilsus",
      "hr": "Emocionalna stabilnost",
      "mk": "Емоционална стабилност",
      "rw": "Guhamya amarangamutima",
      "no": "Følelsesmessig stabilitet",
      "ti": "ስሜታዊ ቋሚነት",
      "kk": "Эмоционалды тұрақтылық",
      "bg": "Емоционална стабилност",
      "lv": "Emocionālā stabilitāte",
      "cs": "Emoční stabilita",
      "az": "Emosional Sabitlik",
      "mn": "Сэтгэл хөдлөлийн тогтвортой байдал",
      "to": "Ma'u ha fiefia fakatu'utamaki",
      "tn": "Boemo jwa Maikutlo jo bo sa fetogeng",
      "af": "Emosionele Stabiliteit",
      "st": "Boemo bo Tsitsitseng ba Maikutlo",
      "sv": "Emotionell stabilitet",
      "ps": "احساساتي ثبات",
      "bn": "আবেগিক স্থিরতা",
      "ht": "Estabilite Emosyonèl",
      "lb": "Emotional Stabilitéit",
      "sr": "Емоционална стабилност",
      "fa": "ثبات عاطفی",
      "hu": "Érzelmi stabilitás",
      "mg": "Fahamarinan-tsaina ara-pihetseham-po",
      "fi": "Tunne-elämän vakaus",
      "sq": "Qëndrueshmëri Emocionale",
      "bm": "Danbe nin yèrèya",
      "ro": "Stabilitate Emoțională",
      "pl": "Stabilność emocjonalna",
      "id": "Stabilitas Emosional",
      "el": "Συναισθηματική Σταθερότητα",
      "ms": "Kestabilan Emosi",
      "zh-tw": "情緒穩定性",
      "sm": "Maua tumau o lagona",
      "vi": "Sự Ổn định Cảm xúc",
      "it": "Stabilità Emozionale",
      "sw": "Utulivu wa Hisia",
      "hi": "भावनात्मक स्थिरता",
      "am": "የስሜት መረጋጋት",
      "qu": "Sumaq kawsaywan kuyakuy kallpachakuy",
      "dz": "བློ་གཏད་ཀྱི་ཆོག་མཐར།",
      "ss": "Kuzinza Imizwa",
      "li": "Emotionele Stabiliteit",
      "la": "Stabilitas Emotionum",
      "fj": "Vakacegu ni Yalo",
      "or": "ଭାବନାତ୍ମକ ସ୍ଥିରତା",
      "ku": "Sabitiyeta Hîssî",
      "mh": "Kōṃṃan kōn karōk",
      "ch": "Hinasso yan Emosión Stability",
      "sn": "Kugadzikana Kwemanzwiro",
      "wo": "Ug nuyu xel bu yomb",
      "so": "Xasilloonida Dareenka",
      "bo": "ཁོང་ཁྲོན་འཕྲོ་བའི་གནས་འགུལ།",
      "ay": "Kuyunakawi ch’amaña",
    }, 
    range: "46–90" 
  },
  C: { 
    name: {
      "ko": "대인관계 및 협업 성향",
      "en": "Interpersonal and Collaborative Tendencies",
      "fr": "Tendances interpersonnelles et collaboratives",
      "es": "Tendencias interpersonales y colaborativas",
      "de": "Zwischenmenschliche und kollaborative Tendenzen",
      "ja": "対人関係と協力的傾向",
      "zh": "人际关系和协作倾向",
      "ru": "Межличностные и коллаборативные тенденции",
      "ar": "الميول الشخصية والتعاونية",
      "pt": "Tendências interpessoais e colaborativas",
      "th": "แนวโน้มระหว่างบุคคลและการทำงานร่วมกัน",
      "tr": "Kişilerarası ve İşbirlikçi Eğilimler",
      "zh-cn": "人际关系和协作倾向",
      "tl": "Mga Interpersonal at Pakikipagtulungang Hilig",
      "nl": "Interpersoonlijke en Samenwerkingsneigingen",
      "tk": "Şahsy we hyzmatdaşlyk ukyby",
      "ky": "Аралык жана кызматташтыкка болгон ыктоо",
      "dv": "އެންޑްަރޕަރސަނަލް އެންޑް ކޮލްލަބަރޭޓިވް ޓެންޑަންސިސް",
      "ak": "Nneyɛe ne Nkabom Adwuma",
      "xh": "Iindlela Zobudlelwane Nezokusebenzisana",
      "zu": "Izitayela Zokuxhumana Nokubambisana",
      "mi": "Ngā Āhuatanga o te Whakawhanaungatanga me te Mahi Ngātahi",
      "ha": "Halayyar hulɗa da haɗin gwiwa",
      "yo": "Ìfarahan Ìbáṣepọ àti Ìfọwọ́sowọpọ",
      "ny": "Makhalidwe a Ubale ndi Mgwirizano",
      "si": "අන්‍යෝන්‍ය සහයෝගී ප්‍රවණතා",
      "gu": "આંતરવ્યક્તિગત અને સહકારી વલણો",
      "ml": "അന്തര്‍വ്യക്തി സഹകരണ പ്രവണതകള്‍",
      "kn": "ಅಂತರವ್ಯಕ್ತಿಗತ ಮತ್ತು ಸಹಕಾರಾತ್ಮಕ ಪ್ರವೃತ್ತಿಗಳು",
      "bs": "Međuljudske i saradničke tendencije",
      "cy": "Tueddau Rhyngbersonol a Chydweithredol",
      "ca": "Tendències interpersonals i col·laboratives",
      "eu": "Joera pertsonalak eta lankidetzakoak",
      "is": "Samskipta- og samvinnutilhneigingar",
      "be": "Міжасобасныя і калабарацыйныя тэндэнцыі",
      "sl": "Medosebne in sodelovalne težnje",
      "sk": "Medziľudské a kolaboratívne tendencie",
      "ga": "Claontaí Idirphearsanta agus Comhoibríocha",
      "da": "Interpersonelle og samarbejdende tendenser",
      "uk": "Міжособистісні та колаборативні тенденції",
      "he": "נטיות בין-אישיות ושיתופיות",
      "ka": "ურთიერთობების და თანამშრომლობის ტენდენციები",
      "my": "ပုဂ္ဂိုလ်ရေးနှင့် ပူးပေါင်းဆောင်ရွက်မှုဆိုင်ရာ傾向များ",
      "ur": "بین الشخصی اور اشتراکی رجحانات",
      "uz": "Shaxsiy va hamkorlik moyilliklari",
      "tg": "Гардиши муоширативу ҳамкорӣ",
      "hy": "Միջանձնային և համագործակցային միտումներ",
      "lt": "Tarpasmeniniai ir bendradarbiavimo polinkiai",
      "et": "Isikutevahelised ja koostöised kalduvused",
      "hr": "Međuljudske i suradničke tendencije",
      "mk": "Меѓучовечки и соработнички тенденции",
      "rw": "Imyitwarire y’imibanire n’imikoranire",
      "no": "Mellommenneskelige og samarbeidende tendenser",
      "ti": "የሰዎች መለያየትና መስራት እንዲያውም",
      "kk": "Адамаралық және ынтымақтастыққа бейімділік",
      "bg": "Междуличностни и сътруднически тенденции",
      "lv": "Starpindividuālās un sadarbības tendences",
      "cs": "Interpersonální a spolupracující tendence",
      "az": "Şəxslərarası və əməkdaşlıq meylləri",
      "mn": "Харилцаа, хамтын ажиллагааны хандлага",
      "to": "Ngaue fakataha mo e feohi",
      "tn": "Maitlamo a botsalano le tirisanommogo",
      "af": "Interpersoonlike en Samewerkende Neigings",
      "st": "Litloaelo tsa ho sebetsa 'moho le batho ba bang",
      "sv": "Interpersonella och samarbetsinriktade tendenser",
      "ps": "شخصي او ګډ کار میلانونه",
      "bn": "আন্তঃব্যক্তিক এবং সহযোগিতামূলক প্রবণতা",
      "ht": "Tandans Entèpèsonèl ak Kolaboratif",
      "lb": "Interperséinlech a Kollektiv Tendenzen",
      "sr": "Међуљудске и сарадничке тенденције",
      "fa": "گرایش‌های بین‌فردی و مشارکتی",
      "hu": "Interperszonális és kooperatív hajlamok",
      "mg": "Fironana ara-piaraha-monina sy fiaraha-miasa",
      "fi": "Vuorovaikutteiset ja yhteistyöhön suuntautuvat taipumukset",
      "sq": "Prirjet Ndërpersonale dhe Bashkëpunuese",
      "bm": "Danbɛ ni tafali kɛyɔrɔ",
      "ro": "Tendințe interpersonale și colaborative",
      "pl": "Tendencje interpersonalne i współpracy",
      "id": "Kecenderungan Interpersonal dan Kolaboratif",
      "el": "Διαπροσωπικές και Συνεργατικές Τάσεις",
      "ms": "Kecenderungan Interpersonal dan Kolaboratif",
      "zh-tw": "人際關係與合作傾向",
      "sm": "Faiga masani i fesootaiga ma le galulue faatasi",
      "vi": "Xu hướng giao tiếp và hợp tác",
      "it": "Tendenze interpersonali e collaborative",
      "sw": "Mwelekeo wa Kijamii na Ushirikiano",
      "hi": "अंतरव्यक्तीय और सहयोगी प्रवृत्तियाँ",
      "am": "የግል እና የተባበሩ 傾向ዎች",
      "qu": "Tendensiyuna runakunap kikinchiwan yanapanakunataq",
      "dz": "མི་དབང་དང་ལག་ལེན་འབྲེལ་བའི་སྐོར།",
      "ss": "Tindlela Tekusebentisana Nalabanye",
      "li": "Interpersoonlijke en Samenwerkende Tendensen",
      "la": "Tendentiae Interpersonales et Collaborativae",
      "fj": "iTovo ni Veidonui kei na Veivukei",
      "or": "ଆନ୍ତଃସ୍ବଭାବିକ ଓ ସହଯୋଗୀ ପ୍ରବୃତ୍ତି",
      "ku": "Hevpeyvî û Hevkarî Nezîkî",
      "mh": "Keļan kobalik im jabdrewōt kōn kōṃṃan",
      "ch": "Fina’ma’ase yan Ayudada Tendençian",
      "sn": "Zvido zveUkama uye Kushandira pamwe",
      "wo": "Tendans yu nit ak jëfandikukat",
      "so": "Dabeecadaha Isdhexgalka iyo Iskaashiga",
      "bo": "མི་གཅིག་གི་འབྲེལ་ལམ་དང་མཉམ་འབྲེལ་རྣམ་འགྱུར།",
      "ay": "Yanapt’awi ukhamaraki masinakapxatawa munasiñanaka",
    }, 
    range: "91–150" 
  },
  D: { 
    name: {
    "ko": "성격 기질 유형 분석",
    "en": "Personality Type Analysis",
    "fr": "Analyse du type de personnalité",
    "es": "Análisis del tipo de personalidad",
    "ar": "تحليل نوع الشخصية",
    "pt": "Análise do Tipo de Personalidade",
    "ja": "性格タイプ分析",
    "th": "การวิเคราะห์ประเภทบุคลิกภาพ",
    "tr": "Kişilik Tipi Analizi",
    "de": "Persönlichkeitstyp-Analyse",
    "zh-cn": "性格类型分析",
    "tl": "Pagsusuri ng Uri ng Personalidad",
    "nl": "Persoonlijkheidstypeanalyse",
    "tk": "Şahsýet görnüşiniň derňewi",
    "ky": "Жеке мүнөз түрүн талдоо",
    "dv": "ސަންގައިގެ ވަކިކުރު ބައްދަލު",
    "ak": "Nkyerɛaseɛ wɔ ɔpɛ adebɔ ne suban",
    "xh": "Uhlalutyo lohlobo lobuntu",
    "zu": "Ukuhlaziya Uhlobo Lomuntu",
    "mi": "Tātari Momo Tangata",
    "ha": "Binciken Nau'in Halayya",
    "yo": "Ìtúpalẹ̀ Irú Èniyàn",
    "ny": "Kuwunika Mtundu wa Umwini",
    "si": "පෞරුෂ වර්ග විශ්ලේෂණය",
    "gu": "વ્યક્તિત્વ પ્રકાર વિશ્લેષણ",
    "ml": "വ്യക്തിത്വ തരം വിശകലനം",
    "kn": "ವ್ಯಕ್ತಿತ್ವ ಪ್ರಕಾರ ವಿಶ್ಲೇಷಣೆ",
    "bs": "Analiza tipa ličnosti",
    "cy": "Dadansoddiad Math o Bersonoliaeth",
    "ca": "Anàlisi del tipus de personalitat",
    "eu": "Nortasun motaren analisia",
    "is": "Persónuleikagerðargreining",
    "be": "Аналіз тыпу асобы",
    "sl": "Analiza tipa osebnosti",
    "sk": "Analýza typu osobnosti",
    "ga": "Anailís ar Chineál Pearsantachta",
    "da": "Personlighedstypeanalyse",
    "uk": "Аналіз типу особистості",
    "he": "ניתוח סוג אישיות",
    "ka": "პიროვნების ტიპის ანალიზი",
    "my": "ကိုယ်ရည်ကိုယ်သွေး အမျိုးအစား ခွဲခြမ်းစိတ်ဖြာခြင်း",
    "ur": "شخصیت کی قسم کا تجزیہ",
    "uz": "Shaxsiyat turi tahlili",
    "tg": "Таҳлили навъи шахсият",
    "hy": "Անհատականության տեսակի վերլուծություն",
    "lt": "Asmenybės tipo analizė",
    "et": "Isiksuse tüübi analüüs",
    "hr": "Analiza tipa osobnosti",
    "mk": "Анализа на типот на личност",
    "rw": "Isesengura ry'ubwoko bw'imico",
    "no": "Personlighetstypeanalyse",
    "ti": "ግለኝነት አይነት ትንተና",
    "kk": "Тұлға түрін талдау",
    "bg": "Анализ на типа личност",
    "lv": "Personības tipa analīze",
    "cs": "Analýza typu osobnosti",
    "az": "Şəxsiyyət növünün təhlili",
    "mn": "Хувь хүний төрлийн шинжилгээ",
    "to": "Fakamatala 'o e fa'ahinga 'o e tangata",
    "tn": "Tshekatsheko ya Mofuta wa Botho",
    "af": "Persoonlikheidstipe-analise",
    "st": "Tlhahlobo ea mofuta oa botho",
    "sv": "Personlighetstypanalys",
    "ps": "د شخصیت ډول تحلیل",
    "bn": "ব্যক্তিত্বের ধরন বিশ্লেষণ",
    "ht": "Analiz Kalite Pèsonalite",
    "lb": "Perséinlechkeetstyp Analys",
    "sr": "Анализа типа личности",
    "fa": "تحلیل نوع شخصیت",
    "hu": "Személyiségtípus elemzés",
    "mg": "Famakafakana ny toetran'ny olona",
    "fi": "Persoonallisuustyyppianalyysi",
    "sq": "Analiza e Tipit të Personalitetit",
    "bm": "Yera bɔ kɔfɛ",
    "ro": "Analiza tipului de personalitate",
    "pl": "Analiza typu osobowości",
    "id": "Analisis Tipe Kepribadian",
    "el": "Ανάλυση τύπου προσωπικότητας",
    "ms": "Analisis Jenis Personaliti",
    "zh-tw": "性格類型分析",
    "sm": "Su'esu'ega o le Ituaiga o Tagata",
    "vi": "Phân tích Loại Tính Cách",
    "it": "Analisi del Tipo di Personalità",
    "sw": "Uchambuzi wa Aina ya Haiba",
    "hi": "व्यक्तित्व प्रकार विश्लेषण",
    "am": "የግለሰብ አይነት ትንተና",
    "qu": "Ñawinchay imataq runaq warmi/kari kikinchay",
    "dz": "མིང་རིགས་ཀྱི་དཔེ་གཞི་ཞིབ་འཇུག།",
    "ss": "Ukuhlaziywa Kwezinhlobo Zabantu",
    "li": "Persoonlikheidstype-analyse",
    "la": "Analysis Typi Personae",
    "fj": "Noda Ni Tiko Vakaitaukei",
    "or": "ବ୍ୟକ୍ତିତ୍ୱ ପ୍ରକାର ବିଶ୍ଲେଷଣ",
    "ku": "Têgihiştin Awa Kişî",
    "mh": "Kōkkure Kajin Ekōļ eo",
    "ch": "Analisis i Tipo Personalidåt",
    "sn": "Kuongorora Rudzi rweHunhu",
    "wo": "Tànk ceet gi nit ñi",
    "so": "Falanqaynta Nooca Shaqsiyadda",
    "bo": "མིང་དབྱེའི་རིགས་ཞིབ་འཇུག",
    "ay": "Amuyt'äwiq wakichäwi jaqinakapana",
    }, 
    range: "151–210" 
  },
  E: { 
    name: {
      "ko": "가치관과 동기",
      "en": "Values and Motivation",
      "fr": "Valeurs et motivation",
      "es": "Valores y motivación",
      "de": "Werte und Motivation",
      "ja": "価値観とモチベーション",
      "zh-cn": "价值观和动机",
      "ru": "Ценности и мотивация",
      "ar": "القيم والدوافع",
      "pt": "Valores e Motivação",
      "th": "ค่านิยมและแรงจูงใจ",
      "tr": "Değerler ve Motivasyon",
      "tl": "Mga Halaga at Motibasyon",
      "nl": "Waarden en Motivatie",
      "tk": "Gadyrlar we Höweslendiriş",
      "ky": "Баалуулуктар жана мотивация",
      "dv": "އަކުރަނބުރި އެންމެ މޯޓިވޭޝަން",
      "ak": "Nokwasɛm ne Ahokyerɛ",
      "xh": "Iinqobo kunye noVuselelo",
      "zu": "Amanani kanye Nokukhuthaza",
      "mi": "Ngā Uara me te Whakamānawa",
      "ha": "Dara da Tushen Aiki",
      "yo": "Iye ati Imọlẹ",
      "ny": "Mikhalidwe ndi Kutsogolera",
      "si": "අගයන් සහ ප්‍රේරණය",
      "gu": "મૂલ્યો અને પ્રેરણા",
      "ml": "മൂല്യങ്ങളും പ്രചോദനവും",
      "kn": "ಮೌಲ್ಯಗಳು ಮತ್ತು ಪ್ರೇರಣೆ",
      "bs": "Vrijednosti i Motivacija",
      "cy": "Gwerthoedd a Chymhelliant",
      "ca": "Valors i Motivació",
      "eu": "Balioak eta Motibazioa",
      "is": "Gildi og Hvatning",
      "be": "Каштоўнасці і Матывацыя",
      "sl": "Vrednote in Motivacija",
      "sk": "Hodnoty a Motivácia",
      "ga": "Luachanna agus Spreagadh",
      "da": "Værdier og Motivation",
      "uk": "Цінності та Мотивація",
      "he": "ערכים ומוטיבציה",
      "ka": "ღირებულებები და მოტივაცია",
      "my": "တန်ဖိုးများနှင့် အားထားမှု",
      "ur": "اقدار اور محرکات",
      "uz": "Qiymatlar va Motivatsiya",
      "tg": "Қимматҳо ва Мотивация",
      "hy": "Արժեքներ և Մոտիվացիա",
      "lt": "Vertybės ir Motyvacija",
      "et": "Väärtused ja Motivatsioon",
      "hr": "Vrijednosti i Motivacija",
      "mk": "Вредности и Мотивација",
      "rw": "Indangagaciro n'Imbaraga",
      "no": "Verdier og Motivasjon",
      "ti": "እሴቶች እና ምርቃን",
      "kk": "Құндылықтар мен Мотивация",
      "bg": "Ценности и Мотивация",
      "lv": "Vērtības un Motivācija",
      "cs": "Hodnoty a Motivace",
      "az": "Dəyərlər və Motivasiya",
      "mn": "Үнэт зүйлс ба Урам зориг",
      "to": "Ngaahi Uhounga mo e Fakamo'ui",
      "tn": "Dikgopolo le Thotloetso",
      "af": "Waardes en Motivering",
      "st": "Litekanyetso le Tšusumetso",
      "sv": "Värderingar och Motivation",
      "ps": "ارزښتونه او هڅونه",
      "bn": "মূল্যবোধ এবং প্রেরণা",
      "ht": "Valè ak Motivasyon",
      "lb": "Wäerter a Motivatioun",
      "sr": "Вредности и Мотивација",
      "fa": "ارزش‌ها و انگیزه",
      "hu": "Értékek és Motiváció",
      "mg": "Soatoavina sy Famporisihana",
      "fi": "Arvot ja Motivaatio",
      "sq": "Vlerat dhe Motivimi",
      "bm": "Danbe ani Jatigiya",
      "ro": "Valori și Motivație",
      "pl": "Wartości i Motywacja",
      "id": "Nilai dan Motivasi",
      "el": "Αξίες και Κίνητρα",
      "ms": "Nilai dan Motivasi",
      "zh-tw": "價值觀和動機",
      "sm": "Uiga ma Fa'aosofiaga",
      "vi": "Giá trị và Động lực",
      "it": "Valori e Motivazione",
      "sw": "Thamani na Motisha",
      "hi": "मूल्य और प्रेरणा",
      "am": "እሴቶች እና ማነስ",
      "qu": "Valorekuna wan Motivación",
      "dz": "གཏིང་དང་རྩོམ་སྒྲིག",
      "ss": "Tigugu ne Kukhutsata",
      "li": "Wäärt en Motivasie",
      "la": "Valores et Motivatio",
      "fj": "iSolisoli kei na Yalovosavosa",
      "or": "ମୂଲ୍ୟବୋଧ ଏବଂ ପ୍ରେରଣା",
      "ku": "Nirx û Hêvî",
      "mh": "Kajojo im Motibwasyon",
      "ch": "Valåres yan Motibasion",
      "sn": "Maitiro uye Kukurudzira",
      "wo": "Njiit ak Motiwasyon",
      "so": "Qiimayaal iyo Dhiirigelin",
      "bo": "གཏིང་དང་རྩོམ་སྒྲིག",
      "ay": "Valoranakasa ukhamaraki Motivasión",
    }, 
    range: "211–255" 
  },
  F: { 
    name: {
      "ko": "사고 방식과 판단",
      "en": "Thinking Style and Judgment",
      "fr": "Style de pensée et jugement",
      "es": "Estilo de pensamiento y juicio",
      "de": "Denkstil und Urteil",
      "ja": "思考スタイルと判断",
      "zh-cn": "思维方式和判断",
      "ru": "Стиль мышления и суждение",
      "ar": "أسلوب التفكير والحكم",
      "pt": "Estilo de Pensamento e Julgamento",
      "th": "รูปแบบการคิดและการตัดสิน",
      "tr": "Düşünme Tarzı ve Yargı",
      "tl": "Estilo ng Pag-iisip at Paghatol",
      "nl": "Denkwijze en Oordeel",
      "tk": "Pikirleniş Stili we Karar",
      "ky": "Ой жүгүртүү стили жана баа берүү",
      "dv": "ތަންގިންސް ސްޓައިލް އެންޑް ޖަރޖްމަންޓް",
      "ak": "Adwene Ho Nneyɛe ne Ɔbu",
      "xh": "Isimbo Sokuqonda kunye noMgwebo",
      "zu": "Isitayela Sokucabanga Nokwahlulela",
      "mi": "Kāhua Whakaaro me te Whakatau",
      "ha": "Salon Tunani da Hukunci",
      "yo": "Ìrísí Ìròyìn àti Ìdájọ́",
      "ny": "Mtundu wa Kuganiza ndi Kuweruza",
      "si": "සිතීමේ ශෛලිය සහ තීරණය",
      "gu": "વિચારશૈલી અને નિર્ણય",
      "ml": "ചിന്താഗതിശൈലിയും വിധിയും",
      "kn": "ಚಿಂತನೆ ಶೈಲಿ ಮತ್ತು ತೀರ್ಮಾನ",
      "bs": "Stil razmišljanja i prosudba",
      "cy": "Arddull Meddwl a Barn",
      "ca": "Estil de pensament i judici",
      "eu": "Pentsamendu Estiloa eta Epaitza",
      "is": "Hugsunarstíll og Dómur",
      "be": "Стыль мыслення і суджэнне",
      "sl": "Slog razmišljanja in presoja",
      "sk": "Štýl myslenia a úsudok",
      "ga": "Stíl Smaointeoireachta agus Breithiúnas",
      "da": "Tænkestil og Dømmekraft",
      "uk": "Стиль мислення та судження",
      "he": "סגנון חשיבה ושיפוט",
      "ka": "ფიქრის სტილი და განსჯა",
      "my": "စဉ်းစားမှုပုံစံနှင့်ဆုံးဖြတ်ချက်",
      "ur": "سوچنے کا انداز اور فیصلہ",
      "uz": "Fikrlash uslubi va hukm",
      "tg": "Услуби тафаккур ва ҳукмронӣ",
      "hy": "Մտածողության ոճ և դատողություն",
      "lt": "Mąstymo stilius ir sprendimas",
      "et": "Mõtlemisstiil ja otsustus",
      "hr": "Stil razmišljanja i prosudba",
      "mk": "Стил на размислување и пресуда",
      "rw": "Imyitwarire yo gutekereza no gufata ibyemezo",
      "no": "Tenkestil og dom",
      "ti": "የማሰብ ዘይቤ እና ፍርድ",
      "kk": "Ойлау стилі мен шешім",
      "bg": "Стил на мислене и преценка",
      "lv": "Domāšanas stils un spriedums",
      "cs": "Styl myšlení a úsudek",
      "az": "Düşüncə tərzi və mühakimə",
      "mn": "Сэтгэх хэв маяг ба шүүлт",
      "to": "Fakalakalaka ʻo e Fakakaukau mo e Fili",
      "tn": "Mokgwa wa Go Akanya le Katlholo",
      "af": "Denkwyse en Oordeel",
      "st": "Mokhoa oa ho Nahana le Kahlolo",
      "sv": "Tankestil och omdöme",
      "ps": "د فکر کولو سټایل او قضاوت",
      "bn": "চিন্তার ধরন এবং বিচার",
      "ht": "Style Panse ak Jijman",
      "lb": "Denkstil a Geriicht",
      "sr": "Стил размишљања и пресуда",
      "fa": "سبک تفکر و قضاوت",
      "hu": "Gondolkodási stílus és ítélet",
      "mg": "Fomba Fisainana sy Fitsarana",
      "fi": "Ajattelutyyli ja arvostelukyky",
      "sq": "Stili i Mendimit dhe Gjykimi",
      "bm": "Fasoni ka fikirɛ ani ka hakɛ",
      "ro": "Stil de gândire și judecată",
      "pl": "Styl myślenia i osąd",
      "id": "Gaya Berpikir dan Penilaian",
      "el": "Στυλ σκέψης και κρίση",
      "ms": "Gaya Berfikir dan Penghakiman",
      "zh-tw": "思維方式和判斷",
      "sm": "Faiga Mafaufauga ma le Faamasinoga",
      "vi": "Phong cách tư duy và phán đoán",
      "it": "Stile di pensiero e giudizio",
      "sw": "Mtindo wa Kufikiri na Hukumu",
      "hi": "सोचने की शैली और निर्णय",
      "am": "የአስተሳሰብ ቅጥያ እና ፍርድ",
      "qu": "Yuyay Silluywan Willanakuy",
      "dz": "བསམ་བློའི་སྒྲིག་སྟངས་དང་དཔག་བསམ།",
      "ss": "Indlela Yokucabanga ne Kwesahlulelo",
      "li": "Denkwies en Oordeel",
      "la": "Stilum Cogitandi et Iudicium",
      "fj": "Vakaisosomi ni Nanuma kei na Lewa",
      "or": "ଚିନ୍ତାଧାରା ଶୈଳୀ ଏବଂ ବିଚାର",
      "ku": "Şêwaza Bifikirin û Dadwerî",
      "mh": "Jeṃṃan in karōk im jikuul",
      "ch": "Hinasso yan Fagagu",
      "sn": "Maitiro Ekufunga uye Kutonga",
      "wo": "Nataal gi xel ak juddu",
      "so": "Qaabka Fikirka iyo Garsoorka",
      "bo": "བསམ་འཆར་དང་ཞིབ་འཇུག་གི་ཐོག་མ།",
      "ay": "Yuyay qillqay ukanaka",
    }, 
    range: "256–300" 
  },
  G: { 
    name: {
      "ko": "타당도 및 반응 왜곡 탐지",
      "en": "Validity and Response Distortion Detection",
      "fr": "Validité et détection de distorsion de réponse",
      "es": "Detección de validez y distorsión de respuesta",
      "de": "Validität und Erkennung von Antwortverzerrungen",
      "ja": "妥当性と反応歪み検出",
      "zh": "有效性和响应扭曲检测",
      "ru": "Обнаружение достоверности и искажения ответов",
      "ar": "التحقق من الصحة واكتشاف تحريف الاستجابات",
      "pt": "Validação e Detecção de Distorção de Respostas",
      "th": "การตรวจสอบความถูกต้องและการบิดเบือนการตอบสนอง",
      "tr": "Geçerlilik ve Yanıt Bozulması Tespiti",
      "zh-cn": "有效性和响应扭曲检测",
      "tl": "Pagpapatunay at Pag-detect ng Pagbaluktot ng Tugon",
      "nl": "Geldigheid en Detectie van Antwoordvertekening",
      "tk": "Dogrulygy we Jogap Egrişmesini Anyklamak",
      "ky": "Жарактуулугун жана жооптордун бурмаланыштарын аныктоо",
      "dv": "ވެލިޑިޓީ އަދި ރިސްޕޯންސް ޑިސްޓޯރޝަން ޑީޓެކްޝަން",
      "ak": "Nokwasɛm ne Nsɛmmisa Fɔrm Sesa Nhwehwɛmu",
      "xh": "Ukuqinisekiswa kunye nokuFumanisa iMpazamo yeMpendulo",
      "zu": "Ukuqinisekiswa Nobunemba bokuphendula",
      "mi": "Aromātai Whakamana me te Hē o ngā Whakautu",
      "ha": "Gaskiya da Gano Wargaza Amsa",
      "yo": "Ìfọwọ́si àti ìwádìí àìtọ́ ninu ìdáhùn",
      "ny": "Kutsimikizira ndi Kuzindikira Kusalondola kwa Mayankho",
      "si": "වලංගුභාවය සහ ප්‍රතිචාර වකෘතිවීම හඳුනා ගැනීම",
      "gu": "માન્યતા અને પ્રતિસાદ વિકૃતિ શોધવી",
      "ml": "വാലിഡിറ്റിയും പ്രതികരണ വിഭ്രമവും കണ്ടെത്തല്‍",
      "kn": "ಮಾನ್ಯತೆ ಮತ್ತು ಪ್ರತಿಕ್ರಿಯೆ ವಿಪರ್ಯಾಸ ಪತ್ತೆ",
      "bs": "Valjanost i otkrivanje izobličenja odgovora",
      "cy": "Dilysrwydd ac Canfod Ystumio Ymatebion",
      "ca": "Validació i detecció de distorsió de respostes",
      "eu": "Baliozkotasuna eta erantzunaren distortsioaren detekzioa",
      "is": "Réttmæti og skekkjuleit í svörum",
      "be": "Правамернасць і выяўленне скажэння адказаў",
      "sl": "Veljavnost in zaznavanje izkrivljanja odgovorov",
      "sk": "Platnosť a detekcia skreslenia odpovedí",
      "ga": "Bailíocht agus Braite Díchumadh Freagartha",
      "da": "Gyldighed og detektion af responsforvrængning",
      "uk": "Визначення достовірності та спотворення відповідей",
      "he": "תוקף וזיהוי עיוות תגובה",
      "ka": "ვალიდურობა და პასუხის დამახინჯების გამოვლენა",
      "my": "တရားဝင်မှုနှင့် တုံ့ပြန်မှုချွတ်ယွင်းမှု စစ်ဆေးခြင်း",
      "ur": "صحت اور جوابی بگاڑ کی شناخت",
      "uz": "Yaroqlilik va javob buzilishining aniqlanishi",
      "tg": "Санҷиши эътимоднокӣ ва таҳрифоти ҷавоб",
      "hy": "Վավերականության և պատասխանների աղավաղման հայտնաբերում",
      "lt": "Galiojimo ir atsako iškraipymo aptikimas",
      "et": "Kehtivuse ja vastuse moonutuse tuvastamine",
      "hr": "Valjanost i otkrivanje iskrivljenja odgovora",
      "mk": "Валидност и откривање на искривување на одговорите",
      "rw": "Kugenzura ukuri n'ikibeshyo mu gusubiza",
      "no": "Gyldighet og deteksjon av responsforvrengning",
      "ti": "ትክክለኛነት እና የምላሽ ስንታወት መወቅ",
      "kk": "Жарамдылық және жауап бұрмалануын анықтау",
      "bg": "Валидност и откриване на изкривяване на отговори",
      "lv": "Derīgums un atbildes sagrozīšanas noteikšana",
      "cs": "Platnost a detekce zkreslení odpovědi",
      "az": "Etibarlılıq və Cavab Təhrifinin Aşkarlanması",
      "mn": "Зөвшөөрөл ба хариу хазайлтын илрүүлэлт",
      "to": "Mo'oni mo e Fekau'aki moe fakalahi'i e tali",
      "tn": "Boamma le Go lemoga Phetogo ya Karabo",
      "af": "Geldigheid en Antwoordvervorming Opsporing",
      "st": "Boikemisetso le Ho Fumana Liphoso tsa Karabo",
      "sv": "Giltighet och upptäckt av svarsförvrängning",
      "ps": "د اعتبار او ځواب تحریف کشف",
      "bn": "বৈধতা ও প্রতিক্রিয়া বিকৃতি সনাক্তকরণ",
      "ht": "Validite ak Deteksyon Deformasyon Repons",
      "lb": "Validitéit an Äntwertverfälschungserkennung",
      "sr": "Ваљидност и откривање изобличења одговора",
      "fa": "تشخیص اعتبار و تحریف پاسخ",
      "hu": "Érvényesség és válasz torzításának felismerése",
      "mg": "Fanamarinana sy Fitiliana Fanodikodinam-baly",
      "fi": "Vakaus ja vastausharhan havaitseminen",
      "sq": "Vlefshmëria dhe zbulimi i shtrembërimit të përgjigjeve",
      "bm": "Jatigiya kɔrɔ ni dɔgɔkun fayida ka bannakiliya",
      "ro": "Validare și detectare a distorsiunii răspunsurilor",
      "pl": "Ważność i wykrywanie zniekształceń odpowiedzi",
      "id": "Validitas dan Deteksi Distorsi Respons",
      "el": "Εγκυρότητα και Ανίχνευση Παραμόρφωσης Απάντησης",
      "ms": "Sahih dan Pengesanan Penyelewengan Respons",
      "zh-tw": "有效性與反應扭曲檢測",
      "sm": "Fa'amaoni ma Su'esu'ega o le Tali Fa'aliliu",
      "vi": "Xác thực và Phát hiện Biến dạng Phản hồi",
      "it": "Validità e Rilevamento di Distorsione delle Risposte",
      "sw": "Uhalali na Ugunduzi wa Upotovu wa Majibu",
      "hi": "वैधता और प्रतिक्रिया विकृति का पता लगाना",
      "am": "ትክክለኛነትና የምላሽ መለዋወጫ ማግኘት",
      "qu": "Riqsisqa kayta chinkaynin kikinchikmanta tarinakuy",
      "dz": "ནུས་ཤུགས་དང་ལན་ཁ་བཀོལ་ནུས་འབྱེད་ནས་འཚོལ་ཞིབ།",
      "ss": "Kuhlola Kwekuvumelana Nephutha Lokuphendula",
      "li": "Geldigheit en Ontdekking van Antwoordvervorming",
      "la": "Validitas et Detectio Distortionis Responsionum",
      "fj": "Vakadeitaki kei na Kilai ni Veisau ni Tali",
      "or": "ବୈଧତା ଏବଂ ପ୍ରତିକ୍ରିୟା ବିକୃତି ପରିଚୟ",
      "ku": "Rastiyê û Dîtina Qelewaziyê ya Bersivê",
      "mh": "Etalle im Etaleen ewon Kōnane kein karōk",
      "ch": "Fitgun Maolek yan Fitma'sse i Fakmåta",
      "sn": "Kuvimbika uye Kunyepa kwekupindura Kuonekwa",
      "wo": "Woneel ak Yiyoo wuyoo fës ci tontu",
      "so": "Saxnaanta iyo Ogaanshaha Jahwareerka Jawaabta",
      "bo": "བདེན་པ་དང་ལན་གྱི་འབྱུང་འགྱུར་ཞིབ་འཇུག།",
      "ay": "Chiqa willt'ayampi mayjt'awi janiwa chhaqtayaña",
    }, 
    range: "301–330" 
  },
  H: { 
    name: {
      "ko": "조직 적합성 종합",
      "en": "Overall Organizational Fit",
      "fr": "Résumé de l'adéquation organisationnelle",
      "es": "Ajuste organizacional general",
      "de": "Gesamtorganisatorische Eignung",
      "ja": "組織適合性の総合",
      "zh": "组织契合度综合",
      "ru": "Общая организационная пригодность",
      "ar": "التوافق التنظيمي العام",
      "pt": "Adequação Organizacional Geral",
      "th": "ความเหมาะสมกับองค์กรโดยรวม",
      "tr": "Genel Kurumsal Uyum",
      "zh-cn": "组织契合度综合",
      "tl": "Pangkalahatang Pagkakaangkop sa Organisasyon",
      "nl": "Algemene Organisatorische Geschiktheid",
      "tk": "Jemi Guramaçylyk Uýgunlygy",
      "ky": "Уюмга жалпы ылайыктуулук",
      "dv": "އޯވަރޯލް އޯގަނިޒޭޝަނަލް ފިޓް",
      "ak": "Bammɔdɔ a ɛfata aban mu",
      "xh": "Ukufaneleka ngokupheleleyo kweNkqubo",
      "zu": "Ukufaneleka Kwenhlangano Okuphelele",
      "mi": "Te Ōrite Whānui ki te Whakahaere",
      "ha": "Daidaituwa gaba ɗaya da ƙungiya",
      "yo": "Ibamu Gbogbogbo pẹlu Ẹgbẹ",
      "ny": "Kugwirizana Kwambiri kwa Bungwe",
      "si": "සම්පූර්ණ සංවිධාන සුදුසුකම",
      "gu": "સૌંદર્યાત્મક સંગઠન બંધબેસાણ",
      "ml": "മൊത്തത്തിലുള്ള സംഘടനാ അനുയോജ്യത",
      "kn": "ಒಟ್ಟು ಸಂಸ್ಥೆಯ ಹೊಂದಾಣಿಕೆ",
      "bs": "Ukupna organizacijska podobnost",
      "cy": "Ffit Sefydliadol Cyffredinol",
      "ca": "Ajust Organitzacional General",
      "eu": "Antolakuntza Orokorreko Egokitzapena",
      "is": "Heildstæður Hæfni Stofnunar",
      "be": "Агульная арганізацыйная прыдатнасць",
      "sl": "Splošna organizacijska primernost",
      "sk": "Celková organizačná vhodnosť",
      "ga": "Oiriúnacht Eagraíochtúil Iomlán",
      "da": "Overordnet Organisatorisk Pasform",
      "uk": "Загальна організаційна відповідність",
      "he": "התאמה ארגונית כללית",
      "ka": "საერთო ორგანიზაციული შესაბამისობა",
      "my": "အဖွဲ့အစည်းဆိုင်ရာ အပြည့်အဝ ကိုက်ညီမှု",
      "ur": "مجموعی تنظیمی مطابقت",
      "uz": "Umumiy Tashkiliy Moslik",
      "tg": "Мутобиқати умумии ташкилотӣ",
      "hy": "Ընդհանուր կազմակերպական համապատասխանություն",
      "lt": "Bendras organizacinis tinkamumas",
      "et": "Üldine organisatsiooniline sobivus",
      "hr": "Ukupna organizacijska podobnost",
      "mk": "Севкупна организациска соодветност",
      "rw": "Ihinduka ryose ryo kwinjira mu muryango",
      "no": "Overordnet Organisatorisk Tilpasning",
      "ti": "አጠቃላይ የድርጅት ተስማሚነት",
      "kk": "Жалпы ұйымдық сәйкестік",
      "bg": "Обща организационна пригодност",
      "lv": "Kopējā organizatoriskā atbilstība",
      "cs": "Celková organizační vhodnost",
      "az": "Ümumi Təşkilati Uyğunluq",
      "mn": "Ерөнхий байгууллагын тохироо",
      "to": "Fetongi faka'akauʻvale ʻo e kautaha",
      "tn": "Bodudi jwa Tlotlontle jwa Mokgatlho",
      "af": "Algehele Organisatoriese Geskiktheid",
      "st": "Tšebelisano e Akaretsang ea Mokhatlo",
      "sv": "Övergripande Organisatorisk Passform",
      "ps": "عمومي سازماني مطابقت",
      "bn": "সামগ্রিক সাংগঠনিক উপযোগিতা",
      "ht": "Adapte òganizasyonèl jeneral",
      "lb": "Allgemeng Organisatoresch Eegentlechkeet",
      "sr": "Општа организациона подобност",
      "fa": "تناسب کلی سازمانی",
      "hu": "Általános szervezeti megfelelés",
      "mg": "Fifanarahana ankapobeny amin’ny fikambanana",
      "fi": "Kokonaisvaltainen Organisatorinen Sopivuus",
      "sq": "Përshtatja e Përgjithshme Organizative",
      "bm": "Jatigiya kan ka fo fɔlɔ",
      "ro": "Potrivire organizațională generală",
      "pl": "Ogólne dopasowanie organizacyjne",
      "id": "Kecocokan Organisasi Secara Keseluruhan",
      "el": "Συνολική Οργανωτική Καταλληλότητα",
      "ms": "Keserasian Organisasi Keseluruhan",
      "zh-tw": "組織整體適配度",
      "sm": "Fetaui Aoao ma le Fa'alapotopotoga",
      "vi": "Phù hợp Tổ chức Tổng thể",
      "it": "Adattamento Organizzativo Complessivo",
      "sw": "Ulinganifu wa Shirika kwa Ujumla",
      "hi": "समग्र संगठनात्मक उपयुक्तता",
      "am": "አጠቃላይ የድርጅት ተስማሚነት",
      "qu": "Maqa organizasyunkuna qawaykuy",
      "dz": "ཚོགས་སྡེའི་མཐུན་འགྱུར་མཐའ་མར།",
      "ss": "Kufanela KweNhlangano Konke",
      "li": "Algemeene Organisatorische Geschiktheid",
      "la": "Idoneitas Organisationalis Universalis",
      "fj": "Dodonu Vaka-Oqo ni Matanitu",
      "or": "ସମ୍ପୂର୍ଣ୍ଣ ସଂଗଠନ ଅନୁକୂଳତା",
      "ku": "Guhertina Giştî ya Rêxistinê",
      "mh": "Ejjab eṃṃan kōṃṃan ejja ke",
      "ch": "Pinin Chuuk Organization Machon",
      "sn": "Kukodzera KweSangano Kwepamusoro",
      "wo": "Yaatal bu wér bu tàggat ak bopp",
      "so": "Isku Ekeysiinta Guud ee Ururka",
      "bo": "སྒྱུར་བཅས་ཀྱི་ཚོགས་པ་འབྲེལ་ལམ།",
      "ay": "Kawkhantawi organizasyuna wawanakapa",
    }, 
    range: "331–360" 
  },
};

export default sectionInfo;
